ios = require 'ios-kit'
kakaotalk = require 'kakaotalk-kit'

# 리소스
r = (file) -> "images/more/#{file}"

### 
카카오톡 :: 더보기

@auther ho.s
@since 2016.09.12
###
class exports.More extends ScrollComponent

	# 생성자
	constructor: (options = {}) ->
		super options
		@props = width: @parent.width, height: @parent.height, backgroundColor: "#F5F5F5", scrollHorizontal: false

		# 네비게이션 :: 오른쪽 :: 설정
		icSettings = ios.utils.svg('<svg width="21px" height="21px" viewBox="677 63 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
									    <!-- Generator: Sketch 39.1 (31720) - http://www.bohemiancoding.com/sketch -->
									    <desc>Created with Sketch.</desc>
									    <defs></defs>
									    <path d="M715.3733,78.9169756 C714.96905,77.4336585 714.36425,76.0312683 713.59775,74.7333659 L715.03415,71.1469756 L710.6357,66.8568293 L706.96595,68.2592195 C705.63455,67.5114146 704.19605,66.9213659 702.6788,66.5239024 L701.0996,63 L694.88045,63 L693.30335,66.5239024 C691.78715,66.9213659 690.34655,67.5114146 689.01515,68.2592195 L685.34435,66.8568293 L680.948,71.1469756 L682.38335,74.7333659 C681.61685,76.0312683 681.01415,77.4336585 680.60465,78.9169756 L677,80.4556098 L677,86.5271707 L680.60465,88.0699024 C681.01415,89.5501463 681.61685,90.9504878 682.3844,92.255561 L680.948,95.8337561 L685.34435,100.128 L689.01515,98.7266341 C690.34655,99.4723902 691.78715,100.062439 693.30335,100.461951 L694.88045,103.981756 L701.0996,103.981756 L702.6788,100.461951 C704.19605,100.062439 705.63455,99.4723902 706.96595,98.7266341 L710.6357,100.128 L715.03415,95.8337561 L713.59775,92.255561 C714.36425,90.9504878 714.96905,89.5501463 715.3733,88.0699024 L718.9832,86.5271707 L718.9832,80.4556098 L715.3733,78.9169756 L715.3733,78.9169756 Z M697.9874,96.8161463 C690.4463,96.8161463 684.33215,90.8480488 684.33215,83.4847317 C684.33215,76.122439 690.4463,70.1533171 697.9874,70.1533171 C705.52955,70.1533171 711.64265,76.122439 711.64265,83.4847317 C711.64265,90.8480488 705.52955,96.8161463 697.9874,96.8161463 L697.9874,96.8161463 Z" id="ico_sett" stroke="none" fill="#DCD7D7" fill-rule="evenodd"></path>
									</svg>')

		# 네비게이션바
		@navBar = new ios.NavBar
			left: false
			title: "더보기"
			right: new ios.View width: icSettings.width, height: icSettings.height, backgroundColor:"transparent", html: icSettings.svg
			blur: false
			titleColor: "white"
			color: "#DCD7D7"
			backgroundColor: "#493334"
			dividerBackgroundColor: "#493334"
		@navBar.parent = @

		# 리스트 영역조정
		@contentInset = top: @navBar.height

		s = @width / 750
		# 더미 이미지
		dummy = new Layer 
			width: 750 * s,	height: 1450 * s
			image: r("dummy.png")
			parent: @content
		@updateContent()