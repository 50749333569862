# Import
kakaotalk = require 'kakaotalk-kit'

root = exports ? this

# Firebase
class Firebase extends Framer.BaseClass
	# Path
	PATH = {}

	PATH.DB = {}
	PATH.DB.CHATS = "chats/"
	PATH.DB.USERS = "users/"
	PATH.DB.MEMBERS = "members/"
	PATH.DB.MESSAGES = "messages/"
	PATH.DB.GROUPS = "groups/"

	PATH.STORAGE = {}
	PATH.STORAGE.IMAGES = "images/"
	PATH.STORAGE.PROFILE = "#{PATH.STORAGE.IMAGES}profiles/"
	PATH.STORAGE.BACKGROUND = "#{PATH.STORAGE.IMAGES}backgrounds/"

	# Firebase 서버시간 사용
	TIMESTAMP = { ".sv": "timestamp" }

	#
	_this = null

	#
	@firebase = undefined
	@auth = undefined
	@database = undefined
	@storage = undefined
	@user = undefined
	@friends = []
	
	#
	_loading = undefined

	# 생성자
	constructor: (cb) ->
		# print "Fireabse constructor"

		_this = this
		unless @_firebase?
			_loading = new kakaotalk.Loading
			_loading.show()

			Utils.domLoadScript "https://www.gstatic.com/firebasejs/3.4.0/firebase.js", =>
				config =
					apiKey: "AIzaSyAxtM49cg4JiFVhs49yEgRV6U0l2awhhK4"
					authDomain: "kakaotalk-2caee.firebaseapp.com"
					databaseURL: "https://kakaotalk-2caee.firebaseio.com"
					storageBucket: "kakaotalk-2caee.appspot.com"
					messagingSenderId: "433983279825"
				firebase.initializeApp(config)

				# print "Fireabse init"

				# 객체 저장
				@firebase = firebase
				@auth = firebase.auth()
				@database = firebase.database()
				@storage = firebase.storage()

				# 콜백
				cb _this if cb

				#
				_loading.dismiss()

	# Auth ----------------------------------------------------------------------------------

	# 인증 :: 로그아웃
	signOut: () ->
		_loading.show()
		@auth.signOut()
		.then () -> console.log "Sign-out successful."; _loading.dismiss();
		.catch (error) -> console.log "An error happened. #{Utils.stringify(error)}"; _loading.dismiss();

	# 인증 : 로그인
	signInWithEmailAndPassword: (email, password) ->
		_loading.show()
		@auth.signInWithEmailAndPassword(email, password)
		.then (user) =>
			console.log "signInWithEmailAndPassword : #{Utils.stringify(user)}"
			@user = user
			
			# 계정, 비번 로컬 저장
			kakaotalk.Storage.get().setAccount email, password
			#
			@getUser()

			#
			_loading.dismiss()
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	# 인증 : 계정생성
	createUserWithEmailAndPassword: (email, password) ->
		_loading.show()
		@auth.createUserWithEmailAndPassword(email, password)
		.then (user) =>
			console.log "createUserWithEmailAndPassword : #{Utils.stringify(user)}"
			@user = user

			# 계정, 비번 로컬 저장
			kakaotalk.Storage.get().setAccount email, password

			#
			@createUser()
			#
			# _loading.dismiss()
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);


	# User ----------------------------------------------------------------------------------

	# 유저 :: 프로필 업데이트
	updateUserProfile: (displayName, photoURL = undefined) ->
		return unless @user

		profile = {}
		profile.displayName = displayName if displayName?
		profile.photoURL = photoURL if photoURL?

		_loading.show()
		@user.updateProfile(profile)
		.then () => 
			_loading.dismiss()
			@updateUser()
			console.log "Update successful. : #{Utils.stringify(@user)}"
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);


	# Database -------------------------------------------------------------------------------

	# 유저 :: 생성
	createUser: () ->
		return unless @user

		_loading.show()
		@database.ref(PATH.DB.USERS).child(@user.uid).set({ 
			"#{kakaotalk.Dto.User.EMAIL}": @user.email
			# "#{kakaotalk.Dto.User.DISPLAY_NAME}": @user.displayName,
			# "#{kakaotalk.Dto.User.PHOTO_URL}": @user.photoURL,
			# "#{kakaotalk.Dto.User.STATUS_MSG}": "",
		})
		.then () => 
			_loading.dismiss()
			@getUser()
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	# 유저 : 업데이트
	updateUser: (statusMsg = null, bgURL = null) ->
		return unless @user

		updates = {}
		updates["#{kakaotalk.Dto.User.DISPLAY_NAME}"] = @user.displayName
		updates["#{kakaotalk.Dto.User.PHOTO_URL}"] = @user.photoURL
		updates["#{kakaotalk.Dto.User.STATUS_MSG}"] = statusMsg if statusMsg?
		updates["#{kakaotalk.Dto.User.BG_URL}"] = bgURL if bgURL?

		_loading.show()
		@database.ref(PATH.DB.USERS).child(@user.uid).update(updates)
		.then () => 
			_loading.dismiss()
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	# 유저 : 정보
	getUser: () ->
		return unless @user

		@database.ref(PATH.DB.USERS).child(@user.uid).on 'value', (snapshot) =>
			return if _.isNull snapshot.val()

			@user.statusMsg = snapshot.val().statusMsg if snapshot.val().statusMsg
			@user.bgURL = snapshot.val().bgURL if snapshot.val().bgURL

			console.log @user

		@database.ref(PATH.DB.USERS).child(@user.uid)

	# 친구 :: 목록
	getFriends: () ->
		return unless @user

		@database.ref(PATH.DB.USERS).on 'value', (snapshot) =>
			return if _.isNull snapshot.val()
			return unless snapshot.exists()

			@friends = []
			for key, value of snapshot.val()
				value.uid = key
				@friends.push(value) if key isnt @user.uid

		@database.ref(PATH.DB.USERS)


	# 채팅 :: 목록
	getChats: (callback) ->
		return unless callback
		return callback() unless @user
		return callback() unless @database

		_loading.show()

		@database.ref(PATH.DB.USERS).child(@user.uid).child(PATH.DB.CHATS)
		.orderByChild("true")
		.on 'value', (snapshot) =>
			if _.isNull snapshot.val()
				_loading.dismiss()
				return callback() 

			#
			chats = _.keys snapshot.val()
			console.log chats
			#
			@database.ref(PATH.DB.CHATS).orderByChild(kakaotalk.Dto.Chat.LAST_MESSAGE_AT).once 'value', (snapshot) =>
				if _.isNull snapshot.val()
					_loading.dismiss()
					return callback() 


				myChats = _.pick snapshot.val(), chats
				console.log "chats", myChats

				callback myChats

				_loading.dismiss()
			, (error) => _loading.dismiss()
		, (error) => _loading.dismiss()

	# 대화방 존재 유무 확인
	existChat: (members, callback) ->
		unless @user
			callback()
			return 

		_loading.show()

		#
		membersIds = []
		membersIds.push @user.uid		
		membersIds.push member.uid for member in members

		#
		@database.ref(PATH.DB.USERS).child(@user.uid).child(PATH.DB.CHATS)
		.orderByValue().once 'value', (snapshot) =>
			if _.isNull snapshot.val()
				_loading.dismiss()
				callback()
				return

			console.log snapshot.val()

			chats = _.keys snapshot.val()

			#
			@database.ref(PATH.DB.MEMBERS).once 'value', (snapshot) =>
				_loading.dismiss()
				if _.isNull snapshot.val()
					callback()
					return

				console.log snapshot.val()

				ms = _.pick snapshot.val(), chats

				console.log "ms", ms

				for key, value of ms
					omit = _.omit value, membersIds
					console.log "omit", omit
					if _.isEmpty(omit)
						callback(key)
						return

				callback()
				return

			_loading.dismiss()

	# 채팅 :: 생성
	# 기존 채팅방이 존재하면 날짜 업데이트 
	# 날짜가 0이면 방에서 나간거임
	createChat: (title, members, callback) ->
		return unless @user

		# 기존 대화방이 있는지 검사
		@existChat members, (chatId) =>
			# 대화방이 있으면 -> 대화방 아이디 전달 
			if chatId
				callback chatId if callback
			# 대화방이 없으면 -> 대화방 만들고 대화방 아이디 전달
			else 
				_loading.show()

				# 대화방 생성
				chat = {}
				chat["#{kakaotalk.Dto.Chat.TITLE}"] = title
				chat["#{kakaotalk.Dto.Chat.CREATE_AT}"] = TIMESTAMP
				chatId = @database.ref(PATH.DB.CHATS).push(chat).key

				# 대화방 멤버 생성
				user = { "#{@user.uid}": false }
				users = {}
				users[member.uid] = false for member in members
				
				# 업데이트 
				updates = {}
				# 대화방 멤버
				updates[PATH.DB.MEMBERS + chatId] = _.extend(user, users)
				# 메시지
				# updates[PATH.DB.MESSAGES + chatId] = ""
				# 유저 대화방 정보
				updates[PATH.DB.USERS + @user.uid + '/' + PATH.DB.CHATS + "/#{chatId}"] = false
				# 참여자들 대화방 정보
				updates[PATH.DB.USERS + member.uid + '/' + PATH.DB.CHATS + "/#{chatId}"] = false for member in members

				@database.ref().update(updates)
				.then () => 
					_loading.dismiss()
					callback chatId if callback
				, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	# 채팅 :: 삭제
	deleteChat: () ->

	# 채팅 :: 입장 
	enterChat: (chatId) ->
		return unless @user
		return unless chatId

		# 
		@database.ref(PATH.DB.USERS).child(@user.uid).child(PATH.DB.CHATS).child(chatId).once 'value', (snapshot) =>
			return if _.isNull(snapshot.val())

			console.log "enterChat", snapshot.val()

			if not snapshot.val()
				updates = {}
				# 대화방 
				updates[PATH.DB.USERS + @user.uid + '/' + PATH.DB.CHATS + "/#{chatId}"] = true
				# 첫 진입 시간 저장 : 최초 한번
				updates[PATH.DB.MEMBERS + chatId + "/#{@user.uid}"] = TIMESTAMP

				@database.ref().update(updates)

	# 채팅 :: 퇴장
	exitChat: () ->


	#
	getMessage: (chatId) ->
		return unless @database
		return unless chatId

		@database.ref(PATH.DB.MESSAGES).child(chatId).orderByChild(kakaotalk.Dto.Message.CREATE_AT)

	sendMessage: (chatId, userId, msg, members, callback) ->
		return unless @database
		return unless chatId

		reads = {}
		reads["#{userId}"] = true
		reads["#{uid}"] = false for uid in _.pluck(members, 'uid')

		message = {}
		message["#{kakaotalk.Dto.Message.TYPE}"] = kakaotalk.Dto.Message.Type.Text
		message["#{kakaotalk.Dto.Message.CREATOR}"] = userId
		message["#{kakaotalk.Dto.Message.MESSAGE}"] = msg
		message["#{kakaotalk.Dto.Message.CREATE_AT}"] = TIMESTAMP
		message["#{kakaotalk.Dto.Message.READ}"] = reads

		push = @database.ref(PATH.DB.MESSAGES).child(chatId).push()
		callback push.key if callback

		# 대화방 정보 업데이트
		updates = {}
		updates["#{kakaotalk.Dto.Chat.LAST_MESSAGE}"] = msg
		updates["#{kakaotalk.Dto.Chat.LAST_MESSAGE_AT}"] = TIMESTAMP
		@database.ref(PATH.DB.CHATS).child(chatId).update(updates)

		# 메시지
		push.set message

	readMessage: (chatId, messageIds) ->
		return unless @database
		return unless chatId
		return unless messageIds

		updates = {}
		updates["#{messageId}/read/#{@user.uid}"] = true for messageId in messageIds

		@database.ref(PATH.DB.MESSAGES).child(chatId).update(updates)


	getMembers: (chatId) ->
		return unless @database
		return unless chatId

		@database.ref(PATH.DB.MEMBERS).child(chatId)

		
	# Storage --------------------------------------------------------------------------------

	# 저장소 :: 프로필 사진 업로드
	uploadUserProfileImage: (file) ->
		return unless @user or file

		_loading.show()
		@storage.ref(PATH.STORAGE.PROFILE).child(@user.uid).put(file, {'contentType': file.type})
		.then (snapshot) =>
			url = snapshot.metadata.downloadURLs[0]
			console.log('File available at : ', url)

			# 프로필 업데이트
			@updateUserProfile(undefined, url)
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	# 저장소 :: 프로필 배경
	uploadUserBackgroundImage: (file) ->
		return unless @user or file

		_loading.show()
		@storage.ref(PATH.STORAGE.BACKGROUND).child(@user.uid).put(file, {'contentType': file.type})
		.then (snapshot) =>
			url = snapshot.metadata.downloadURLs[0]
			console.log('File available at : ', url)

			# 프로필 업데이트
			# @updateUserProfile(undefined, url)
			@updateUser undefined, url

		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	# 저장소 :: 메타데이터
	getUserBackgroundImageMetadata: ->
		return unless @user

		_loading.show()
		@storage.ref(PATH.STORAGE.BACKGROUND).child(@user.uid).getMetadata()
		.then (metadata) =>
			_loading.dismiss();
			console.log 'metadata : ', metadata
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);		

# 싱글톤 패턴
class FirebaseJS
	instance = null
	@get: (cb) -> instance ?= new Firebase(cb)

# Export
root.FirebaseJS = FirebaseJS