# Import
kakaotalk = require 'kakaotalk-kit'
{Firebase} = require 'firebase'

root = exports ? this

# Firebase
class Data
	# Path
	PATH = {}
	PATH.CHATS = "/chats"
	PATH.USERS = "/users"
	PATH.MEMBERS = "/members"
	PATH.MESSAGES = "/messages"
	PATH.GROUPS = "/groups"

	# Firebase 서버시간 사용
	TIMESTAMP = { ".sv": "timestamp" }

	#
	_this = null
	#
	_firebase = null

	#
	_account = null

	_userKey = null
	_user = null

	_friends = []
	_chats = []

	constructor: () ->
		if _.isNull(_firebase)
			_firebase = new Firebase
				projectID: "kakaotalk-2caee"
				secret: "ukf3PSrnV538sD28esktYkTeQbTNMPl80toydFlA"
				server: "s-usc1c-nss-109.firebaseio.com"
				debug: false
			_this = this

			# Connection
			_firebase.onChange "connection", => print "Current connection status: #{_firebase.status}"

	#
	get: -> _firebase

	#
	setAccount: (account) -> _account = account

	user: -> _user
	friends: -> _friends
	chats: -> _chats

	# -----------------------------------------------------------
	## GET

	# 유저
	getUser: (cb) ->
		print "getUser"
		_firebase.get PATH.USERS, (result) ->
			print result

			# 계정이 없는 경우 -> 계정 생성
			if result.error? || (_.isObject(result) && _.isEmpty(result))
				createUser _account, cb
			# 계정이 있는 경우
			else 
				_userKey = _.keys(result)[0]
				_user = result[_userKey]

				#
				updateUser result, cb

		, { orderBy: "name", equalTo: _account.name }

	# 친구
	getFriends = (user, cb) ->
		print "getFriends"
		_firebase.get PATH.USERS, (result) ->
			_friends = []

			for key, value of result
				print "#{key} : #{Utils.stringify(value)}"
				value.key = key
				_friends.push(value) if key != _userKey

			print _friends

			#
			cb(result) if cb?
		, { orderBy: "nickname" }

	getFriends: (cb) -> getFriends _user, cb

	## 채팅
	getChats = (user, cb) ->
		print "getChats"
		print user
		_firebase.get PATH.CHATS, (chats) ->
			_chats = []

			for key, value of chats
				print "#{key} : #{Utils.stringify(value)}"
				for userChat, chatTime of user.chats
					print "#{key} / #{userChat}"
					value.key = key
					_chats.push(value) if key == userChat
					
			print _chats

			#
			cb(chats, _chats) if cb?

	# 	, { orderBy: "$key", equalTo: user.chats }
	getChats: (cb) -> getChats _user, cb

	# -----------------------------------------------------------
	## POST

	# 유저
	createUser =  (user, cb) ->
		print "createUser"
		#
		user.at = TIMESTAMP
		# 사용자 생성
		_firebase.post PATH.USERS, user, (result) ->
			print "createUser : #{Utils.stringify(result)}"

			_this.getUser cb

	# CHAT
	createChat: (title, cb) ->
		print "createChat"
		# 데이터
		chat = title: title, createAt: TIMESTAMP, lastMessage: "대화방을 생성하였습니다.", lastMessageAt: TIMESTAMP
		# 방생성
		_firebase.post PATH.CHATS, chat, (result) ->
			print "createChat : #{Utils.stringify(result)}"
			# 사용자 정보의 채팅방 정보 업데이트
			if !result.error? && _.isObject(result) && !_.isEmpty(result) && _.has(result, "name")
				addChatInUser _userKey, result.name, cb

	# -----------------------------------------------------------
	## PATCH

	# 사용자 정보 업데이트 : 닉네임, 상태메시지
	updateUser = (result, cb) ->
		print "updateUser"
		account = _.pick(_account, kakaotalk.Dto.User.NICKNAME, kakaotalk.Dto.User.STATUS_MSG)
		user = _.pick(_user, kakaotalk.Dto.User.NICKNAME, kakaotalk.Dto.User.STATUS_MSG)
		
		unless _.isEqual(account, user)
			_firebase.patch "#{PATH.USERS}/#{_userKey}", account, (result) ->
				print "updateUser : #{Utils.stringify(result)}"
				#
				_this.getUser cb
		else
			cb(result) if cb?

	# 사용자 정보에 채팅방 정보 추가
	addChatInUser = (userKey, chatKey, cb) ->
		print "addChatInUser: #{userKey} / #{chatKey}"
		_firebase.patch "#{PATH.USERS}/#{userKey}#{PATH.CHATS}", { "#{chatKey}": TIMESTAMP }, (result) ->
			print "addChatInUser : #{Utils.stringify(result)}"
			#
			_this.getUser cb



# 싱글톤 패턴
class FirebaseData
	instance = null
	@get: () -> instance ?= new Data()

# Export
root.FirebaseData = FirebaseData