ios = require 'ios-kit'
kakaotalk = require 'kakaotalk-kit'

### 
카카오톡 :: 계정

@auther ho.s
@since 2016.09.21
###
class exports.SignUpAccount extends Layer
	# 생성자
	constructor: (options = {}) ->
		super options
		@props = width: @parent.width, height: @parent.height, backgroundColor: kakaotalk.R.colors.Y1
		# main = @custom

		# 상태바
		# main.statusBar.theme = "dark"

		# 네비게이션바
		@navBar = new ios.NavBar
			left: false
			title: "카카오계정"
			right: false
			blur: false
			titleColor: "black"
			color: "rgba(255,255,255,.85)"
			backgroundColor: "transparent"
			dividerBackgroundColor: "#E5D019"
			superLayer: @

		title = new ios.Text
			name: ".title"
			constraints:
				top: [@navBar, 25]
				align: "horizontal"
			text: "이전 카카오톡 정보를 불러올 수 있어요."
			fontSize: 17
			color: 'black'
			opacity: .85
			superLayer: @

		inputbox = new ios.View
			name: ".inputbox"
			constraints:
				top: [title, 15]
				leading: 13 
				trailing: 88
				height: 91
			backgroundColor: "white"
			borderRadius: ios.px(3.5)
			borderColor: 'rgba(0,0,0,.06)'
			borderWidth: ios.px(0.5)
			superLayer: @

		inputbox.email = new kakaotalk.InputField
			name: ".inputbox.email"
			width: inputbox.width, height: ios.px(inputbox.constraints.height/2)
			backgroundColor: ""
			style: { paddingLeft: "#{ios.px(10)}px", paddingRight: "#{ios.px(10)}px" }
			type: "email", indent: 0, fontSize: ios.px(16), fontWeight: 400
			placeHolder: "카카오계정(이메일)", placeHolderFocus: "id@kakaocorp.com", placeHolderColor: "rgba(0,0,0,.3)"
			autoCapitalize: false, autoComplete: false, autoCorrect: false
			match: "@kakaocorp.com", pattern: "^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@kakaocorp.com$"
			parent: inputbox
		ios.layout.set(inputbox.email)

		div = new ios.View
			name: ".inputbox.div"
			constraints:
				leading: 0
				trailing: 0
				height: .5
				align: "vertical"
			backgroundColor: "black"
			opacity: .15
			superLayer: inputbox

		inputbox.password = new kakaotalk.InputField
			name: ".inputbox.password"
			y: div.maxY
			width: inputbox.width, height: ios.px(inputbox.constraints.height/2)
			backgroundColor: ""
			style: { paddingLeft: "#{ios.px(10)}px", paddingRight: "#{ios.px(10)}px" }
			type: "password", indent: 0, fontSize: ios.px(16), fontWeight: 400
			placeHolder: "비밀번호", placeHolderFocus: "", placeHolderColor: "rgba(0,0,0,.3)"
			autoCapitalize: false, autoComplete: false, autoCorrect: false
			parent: inputbox
		ios.layout.set(inputbox.password)

		loginBtn = new ios.View
			name: ".loginBtn"
			constraints:
				topEdges: inputbox
				leading: [inputbox, 5]
				trailing: 13
				# width: 70
				height: 91
			backgroundColor: "#523637"
			borderRadius: ios.px(3.5)
			borderColor: 'rgba(0,0,0,.06)'
			superLayer: @

		loginBtn.label = new ios.Text
			name: ".loginBtn.label"
			constraints: align: "center"
			text: "로그인"
			fontSize: 16
			color: "white"
			opacity: .3
			superLayer: loginBtn

		forgetFind = new ios.Text
			name: ".forgetFind"
			constraints:
				top: [inputbox, 28]
				align: "horizontal"
			text: "<u>이메일 또는 비밀번호를 잊으셨나요?</u>"
			fontSize: 13
			color: 'black'
			opacity: .50
			superLayer: @

		div = new ios.View
			name: ".div"
			constraints:
				top: [forgetFind, 41]
				leading: 13
				trailing: 13
				height: .5
				align: "horizontal"
			backgroundColor: "black"
			opacity: .20
			superLayer: @

		restore = new ios.Text
			name: ".restore"
			constraints:
				top: [div, 39]
				align: "horizontal"
			text: "카카오계정에 로그인하지 않으면 이전 사용자 정보를<br/>복원할 수 없습니다."
			textAlign: "center"
			fontSize: 13
			lineHeight: 15
			color: '#E54E25'
			superLayer: @

		newStartBtn = new ios.View
			name: ".newStartBtn"
			constraints:
				top: [restore, 15]
				leading: 13
				trailing: 13
				height: 44
			backgroundColor: "#FFE400"
			borderRadius: ios.px(3.5)
			borderColor: 'rgba(0,0,0,.18)'
			borderWidth: ios.px(0.5)
			superLayer: @

		newStartBtn.label = new ios.Text
			name: ".newStartBtn.label"
			constraints: align: "center"
			text: "새로운 카카오톡 시작하기"
			fontSize: 16
			color: "black"
			opacity: .85
			superLayer: newStartBtn

		# 이벤트 --------------------------------------------------------------

		# 이메일 or 비밀번호 찾기
		forgetFind.onClick =>
			sheet = new ios.Sheet 
				actions: ["이메일 찾기", "비밀번호 재설정"]
				exit: "취소"
				animated: true
				target: @
			sheet.overlay.onClick => sheet.dismiss()
			sheet.actions["이메일 찾기"].onClick -> console.log "이메일 찾기"
			sheet.actions["비밀번호 재설정"].onClick -> console.log "비밀번호 재설정"

		# 입력검사
		intput = ->
			if !_.isEmpty(inputbox.email.value.trim()) && !_.isEmpty(inputbox.password.value.trim())
				loginBtn.label.opacity = .8
			else 
				loginBtn.label.opacity = .3

		inputbox.email.on Events.Input, intput
		inputbox.password.on Events.Input, intput

		emailValid = false
		inputbox.email.on Events.Valid, -> emailValid = true
		inputbox.email.on Events.Invalid, -> emailValid = false

		# 로그인
		loginBtn.onClick =>
			# 활성화
			return unless loginBtn.label.opacity == .8
			
			# 이메일 유효성 체크 -> 알림 팝업
			if !emailValid then new ios.Alert title: "이메일 오류", message: "이메일을 확인하세요.", actions: ["확인"]
			# -> 로그인 -> 메인
			else
				kakaotalk.Firebase.get().signInWithEmailAndPassword(inputbox.email.value, inputbox.password.value)
				.then (user) => 
					@parent.complete()
				, (error) => new ios.Alert title: "로그인 오류", message: error.message, actions: ["확인"]

		# 새로운 카카오톡 시작하기 : 입력한 정보로 가입
		newStartBtn.onClick => 
			# 활성화
			return unless loginBtn.label.opacity == .8
			
			# 이메일 유효성 체크 -> 알림 팝업
			if !emailValid then new ios.Alert title:"이메일 오류", message:"이메일을 확인하세요.", actions:["확인"] 
			# -> 개인정보
			else new kakaotalk.SignUpTerms parent: @parent, home: @home, account: {email:inputbox.email.value, password:inputbox.password.value}