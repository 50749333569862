# Import
{R} = require 'kakaotalk-resource'
{Utils} = require 'kakaotalk-utils'
{File} = require 'kakaotalk-file'

# {FirebaseData} = require 'kakaotalk-firebase'
{FirebaseJS} = require 'kakaotalk-firebase-js'
{StorageS} = require 'kakaotalk-storage'
Dto = require 'kakaotalk-dto'

{Loading} = require 'kakaotalk-loading'
{StatusBar} = require 'kakaotalk-status-bar'
{Splash} = require 'kakaotalk-splash'
{SignUp} = require 'kakaotalk-signup'
{SignUpAccount} = require 'kakaotalk-signup-account'
{SignUpTerms} = require 'kakaotalk-signup-terms'
{SignUpProfile} = require 'kakaotalk-signup-profile'
{Profile} = require 'kakaotalk-profile'
{ProfileManage} = require 'kakaotalk-profile-manage'
{ProfileManageInput} = require 'kakaotalk-profile-manage-input'
{Friend} = require 'kakaotalk-friend'
{Chatting} = require 'kakaotalk-chatting'
{ChattingRoom} = require 'kakaotalk-chatting-room'
{Message} = require 'kakaotalk-message'
{MessageHelper} = require 'kakaotalk-message-helper'
{MessageDateDivider} = require 'kakaotalk-message-datedivider'
{Channel} = require 'kakaotalk-channel'
{More} = require 'kakaotalk-more'
{Empty} = require 'kakaotalk-empty'
{SearchBar} = require 'kakaotalk-search-bar'

# Export
exports.R = R
exports.Utils = Utils
exports.File = File

# exports.Firebase = FirebaseData
exports.Firebase = FirebaseJS
exports.Storage = StorageS
exports.Dto = Dto

exports.Loading = Loading
exports.Status = StatusBar
exports.Splash = Splash
exports.SignUp = SignUp
exports.SignUpAccount = SignUpAccount
exports.SignUpTerms = SignUpTerms
exports.SignUpProfile = SignUpProfile
exports.Profile = Profile
exports.ProfileManage = ProfileManage
exports.ProfileManageInput = ProfileManageInput
exports.Friend = Friend
exports.Chatting = Chatting
exports.ChattingRoom = ChattingRoom
exports.Message = Message
exports.MessageHelper = MessageHelper
exports.MessageDateDivider = MessageDateDivider
exports.Channel = Channel
exports.More = More
exports.Empty = Empty
exports.SearchBar = SearchBar


{InputField} = require 'InputField'
exports.InputField = InputField
