ios = require 'ios-kit'
kakaotalk = require 'kakaotalk-kit'

### 
카카오톡 :: 계정 :: 프로필입력

@auther ho.s
@since 2016.09.23
###
class exports.SignUpProfile extends Layer
	# 생성자
	constructor: (options = {}) ->
		super options
		@props = width: @parent.width, height: @parent.height, backgroundColor: kakaotalk.R.colors.Y1
		# main = @custom

		# 상태바
		# main.statusBar.theme = "dark"

		# 네비게이션바
		navBar = new ios.NavBar
			left: false
			title: "프로필 입력"
			right: "완료"
			blur: false
			titleColor: "black"
			color: "rgba(0,0,0,.3)"
			backgroundColor: "transparent"
			dividerBackgroundColor: "#E5D019"
			superLayer: @

		photo = new ios.View
			name: ".photo"
			constraints:
				top: [navBar, 20]
				width: 110
				height: 110
				align: "horizontal"
			backgroundColor: "white"
			borderRadius: "100%"
			borderWidth: ios.px(0.5)
			borderColor: "#EBD800"
			superLayer: @

		icPhoto = ios.utils.svg '<svg width="80px" height="70px" viewBox="70 75 80 70" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
								    <!-- Generator: Sketch 40 (33762) - http://www.bohemiancoding.com/sketch -->
								    <desc>Created with Sketch.</desc>
								    <defs></defs>
								    <path d="M78.0000511,86 C73.5928495,86.4285714 70,90.0087461 70,94.4251154 L70,137.003456 C70,141.414777 73.5817449,145 78.0000511,145 L141.999949,145 C146.40715,145 150,141.419825 150,137.003456 L150,94.4251154 C150,90.0137944 146.418255,86.4285714 141.999949,86 L133.509654,86 L130.259552,79.54216 C129.072223,77.0264111 125.866661,75 123.092158,75 L96.872785,75 C94.0903805,75 90.8893302,77.0335943 89.7053909,79.54216 L86.4552894,86 L78.0000511,86 Z M110.142857,136.285714 C122.293122,136.285714 132.142857,126.435979 132.142857,114.285714 C132.142857,102.13545 122.293122,92.2857143 110.142857,92.2857143 C97.9925926,92.2857143 88.1428571,102.13545 88.1428571,114.285714 C88.1428571,126.435979 97.9925926,136.285714 110.142857,136.285714 Z M110.285714,127.428571 C117.465416,127.428571 123.285714,121.608273 123.285714,114.428571 C123.285714,107.24887 117.465416,101.428571 110.285714,101.428571 C103.106013,101.428571 97.2857143,107.24887 97.2857143,114.428571 C97.2857143,121.608273 103.106013,127.428571 110.285714,127.428571 Z" id="Rectangle-10" stroke="none" fill="#90C8E4" fill-rule="evenodd"></path>
								</svg>'
		photo.icon = new ios.View
			name: ".photo.icon"
			constraints: align: "center"
			width: icPhoto.width
			height: icPhoto.height
			html: icPhoto.svg
			scale: 1/ios.device.scale
			backgroundColor: "transparent"
			superLayer: photo
		photo.icon.center()

		photo.preview = new ios.View
			name: ".photo.preview"
			constraints: 
				width: 110
				height: 110
				align: "center"
			backgroundColor: "transparent"
			borderRadius: "100%"
			borderWidth: ios.px(0.5)
			borderColor: "#EBD800"
			superLayer: photo
		photo.preview.center()

		photo.file = new kakaotalk.File
			name: ".photo.file"
			width: photo.width, height: photo.height
			preview: photo.preview
			parent: photo
		photo.file.onChange (file) => 
			# 프로필 사진 업로드 & 프로필 사진 경로 업데이트
			kakaotalk.Firebase.get().uploadUserProfileImage(file)
			.catch (error) -> new ios.Alert title: "사진 업로드 오류", message: error.message, actions: ["확인"]

		# ------------------------------------------------------
		# 파일 
		###
		file = undefined
		metadata = undefined

		input = document.createElement "input"
		input.name = ".photo.file"
		input.type = "file"
		input.accept = "image/*"
		# 파일 : 스타일
		_.extend input.style,
			font: "400 111px/111px #{Utils.deviceFont()}"
			# outline: "none"
			backgroundColor: "transparent"
			height: "100%"
			width:  "100%"
			# pointerEvents: "none"
			cursor: "pointer"
			margin: "0"
			padding: "0"
			borderRadius: "100%"
			opacity: "0"
			"-webkit-appearance": "none"
		photo._element.appendChild input

		# 파일 : 이벤트
		Events.wrap(input).addEventListener "change", (event) ->
			event.stopPropagation()
			event.preventDefault()
			
			file = event.target.files[0]
			# metadata = 'contentType': file.type

			# 프로필 사진 업로드 & 프로필 사진 경로 업데이트
			kakaotalk.Firebase.get().uploadUserProfileImage(file)
			
			reader = new FileReader()
			reader.readAsDataURL(file)
			
			# 프리뷰
			reader.onload = ->
				tempImage = new Image() 
				tempImage.src = reader.result 
				tempImage.onload = ->
					canvas = document.createElement('canvas')
					canvasContext = canvas.getContext("2d")
					
					canvas.width = tempImage.width
					canvas.height = tempImage.height
					
					canvasContext.drawImage(this, 0, 0, tempImage.width, tempImage.height)
					
					dataURI = canvas.toDataURL("image/jpeg")
					
					# 프리뷰
					photo.preview.image = dataURI
		###

		displayName = new kakaotalk.InputField
			name: ".displayName"
			x: Align.center, y: photo.maxY + ios.px(20.5)
			width: ios.px(219), height: ios.px(44)
			backgroundColor: "white", borderRadius: ios.px(3.5), borderWidth: ios.px(.5), borderColor: "rgba(0,0,0,.06)"
			style: { paddingLeft: "#{ios.px(14.5)}px", paddingRight: "#{ios.px(14.5)}px" }
			type: "text", indent: 0, fontSize: ios.px(16), fontWeight: 400
			placeHolder: "이름 입력", placeHolderFocus: "", placeHolderColor: "rgba(0,0,0,.3)"
			autoCapitalize: false, autoComplete: false, autoCorrect: false, maxLength: 20
			parent: @

		displayNameLimit = new ios.Text
			name: ".displayNameLimit"
			constraints:
				trailingEdges: displayName
				bottom: [displayName, 7]
			text: "0/20"
			textAlign: "right"
			fontSize: 11
			color: "rgba(0,0,0,.3)"
			fontFamily: "Helvetica"
			superLayer: @

		backup = new ios.Text
			name: ".backup"
			constraints:
				top: [displayName, 30]
				width: 229
				align: "horizontal"
			text: "대화백업이력이 있다면 가입이 완료된 이후에<br/>복원이 진행됩니다."
			textAlign: "center"
			fontSize: 13
			lineHeight: 15
			color: "rgba(0,0,0,.5)"
			superLayer: @

		autoAddFriends = new ios.Text
			name: ".autoAddFriends"
			constraints: 
				top: [backup, 29]
				align: "horizontal"
			text: "주소록 친구 자동 추가"
			textAlign: "center"
			fontSize: 13
			letterSpacing: ios.px(-0.2)
			color: "rgba(0,0,0,.5)"
			superLayer: @

		autoAddFriends.check = new ios.View
			name: ".autoAddFriends.check"
			constraints:
				trailing: [autoAddFriends, 8]
				verticalCenter: autoAddFriends
				width: 17
				height: 17
			borderRadius: ios.px(5)
			borderWidth: ios.px(1)
			borderColor: "#D2C20B"
			backgroundColor: "#E0D016"
			superLayer: @

		icCheck = ios.utils.svg '<svg width="24px" height="18px" viewBox="6 11 24 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
								    <!-- Generator: Sketch 40 (33762) - http://www.bohemiancoding.com/sketch -->
								    <desc>Created with Sketch.</desc>
								    <defs></defs>
								    <path d="M25.5535,11.6015 L14.2175,23.0505 L9.4685,18.2365 C8.6755,17.4335 7.3885,17.4335 6.5955,18.2365 C5.8015,19.0385 5.8015,20.3405 6.5955,21.1435 L12.7785,27.4115 C13.5715,28.2135 14.8585,28.2135 15.6515,27.4115 L28.4265,14.5095 C29.2205,13.7065 29.2205,12.4045 28.4265,11.6015 C27.6335,10.7995 26.3465,10.7995 25.5535,11.6015 Z" id="Imported-Layers" stroke="none" fill="#665D0A" fill-rule="evenodd"></path>
								</svg>'
		autoAddFriends.check.icon = new ios.View
			name: ".autoAddFriends.check.icon"
			constraints: align: "center"
			width: icCheck.width
			height: icCheck.height
			html: icCheck.svg
			backgroundColor: "transparent"
			scale: 1/ios.device.scale
			superLayer: autoAddFriends.check
		autoAddFriends.check.icon.center()

		icHelp = ios.utils.svg '<svg width="17px" height="17px" viewBox="283 0 34 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
								    <!-- Generator: Sketch 40 (33762) - http://www.bohemiancoding.com/sketch -->
								    <desc>Created with Sketch.</desc>
								    <defs></defs>
								    <g id="ico_?" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(283.000000, 0.000000)">
								        <circle id="Oval-38" fill="#665D0A" cx="17" cy="17" r="17"></circle>
								        <text id="?" font-family=".HelveticaNeueDeskInterface-Regular, .Helvetica Neue DeskInterface" font-size="26" font-weight="normal" fill="#FDF01B">
								            <tspan x="10" y="27">?</tspan>
								        </text>
								    </g>
								</svg>'
		autoAddFriends.help = new ios.View
			name: ".autoAddFriends.help"
			constraints:
				leading: [autoAddFriends, 7]
				verticalCenter: autoAddFriends
			width: icHelp.width
			height: icHelp.height
			html: icHelp.svg
			backgroundColor: "transparent"
			superLayer: @

		# 이름 입력
		displayName.on Events.Input, ->
			count = displayName.value.length
			
			displayNameLimit.html = "#{count}/20"
			textFrame = ios.utils.textAutoSize(displayNameLimit)
			displayNameLimit.props = width: textFrame.width, height: textFrame.height
			displayNameLimit.maxX = displayName.maxX

			#
			navBar.right.label.color = if count > 0 then "#E54E25" else "rgba(0,0,0,.3)"
		
		# ?
		autoAddFriends.help.onClick ->
			alert = new ios.Alert
				title: ""
				message: "내 폰 주소록에 저장된 전화번호로 카카오톡을 사용중인 친구들을 자동으로 추가합니다.<br/><br/>이 설정은 '더보기탭 > 설정 > 친구관리' 에서 다시 변경할 수 있습니다."
				actions: ["취소", "확인"]

		# 프로필 입력 완료 -> Firebase 프로필 업데이트
		navBar.right.onClick =>
			return if _.isEmpty(displayName.value)

			# 프로필 이름 업데이트
			kakaotalk.Firebase.get().updateUserProfile(displayName.value)
			.then () => @parent.complete()