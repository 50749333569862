# Import
kakaotalk = require 'kakaotalk-kit'

root = exports ? this

# Web Storage
class Storage extends Framer.BaseClass

	ACCOUNT = {}
	ACCOUNT.EMAIL = "email"
	ACCOUNT.PASSWORD = "password"

	constructor: () ->
		if _.isUndefined(Storage)
			console.log("Sorry! No Web Storage support..")
		else
			console.log("Code for localStorage/sessionStorage.")
			

	# 저장 : 로그인 정보
	setAccount: (email, password) ->
		localStorage.setItem(ACCOUNT.EMAIL, email)
		localStorage.setItem(ACCOUNT.PASSWORD, password)

	# 얻기 : 로그인 정보
	getAccount: -> 
		email = localStorage.getItem(ACCOUNT.EMAIL)
		password = localStorage.getItem(ACCOUNT.PASSWORD)
		if email and password 
			return { email: email, password: password }
		else 
			return undefined

	# 삭제 : 로그인 정보
	clearAccount: ->
		localStorage.removeItem(ACCOUNT.EMAIL)
		localStorage.removeItem(ACCOUNT.PASSWORD)

# 싱글톤 패턴
class StorageS
	instance = null
	@get: () -> instance ?= new Storage()

# Export
root.StorageS = StorageS