### 
카카오톡 :: 리소스

@auther ho.s
@since 2016.09.02
###
class exports.R

	@colors:
		R: "#E54E25"
		Y1: "#FFEB00"
		Y2: "#665D0A"
		GR: "#06ACBE"
		BRW: "#493334"

	@font: Framer.Utils.deviceFont()

	@drawable:
		profile: '<svg width="105px" height="106px" viewBox="60 0 210 212" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
				    <!-- Generator: Sketch 39.1 (31720) - http://www.bohemiancoding.com/sketch -->
				    <desc>Created with Sketch.</desc>
				    <defs>
				        <path d="M105,210 C162.989899,210 210,162.989899 210,105 C210,47.0101013 162.989899,0 105,0 C47.0101013,0 0,47.0101013 0,105 C0,162.989899 47.0101013,210 105,210 Z" id="path-1"></path>
				        <path d="M130.958938,168.570499 C129.210543,167.573165 127.857333,166.494319 127.090914,165.266832 C123.759383,159.927742 123.244444,154.326011 123.967753,148.09987 C130.930198,142.434729 137.523802,130.016011 138.534519,124.998178 C145.602346,127.117511 145.137704,115.672152 147.901605,110.256345 C153.568321,99.1514217 150.572099,93.1170755 143.707852,94.5027935 C143.65037,94.764114 143.609654,95.0158448 143.549778,95.2795627 C143.544988,95.3155243 143.542593,94.5291653 143.542593,94.5291653 C154.365877,45.5639345 117.735679,26.5714473 117.833877,31.8553961 C117.984765,40.1457294 115.577852,42.0540884 95.5287901,46.4342038 C74.2965679,51.0732422 66.5844691,62.5641525 64.598963,75.0499986 C63.0062469,85.1360114 66.1413827,94.5291653 66.1413827,94.5291653 C59.1549877,93.0067935 56.0701481,99.0579217 61.7847654,110.256345 C64.5462716,115.672152 64.0816296,127.117511 71.1494568,124.998178 C72.1601728,130.016011 78.5741481,142.434729 85.5365926,148.09987 C86.2575062,154.326011 85.5389877,159.927742 82.2098519,165.266832 C81.6781481,166.117922 81.244642,166.899486 80.5907901,167.630704 C70.0788642,173.379755 47.2467407,174.247627 31.1806667,179.495614 C9,186.740665 9,211.515768 9,211.515768 L200.650444,211.515768 C200.650444,211.515768 200.650444,186.740665 178.467383,179.495614 C163.316222,174.544909 142.136691,173.494832 130.958938,168.570499 Z" id="path-3"></path>
				        <mask id="mask-4" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="191.650444" height="180.515735" fill="white">
				            <use xlink:href="#path-3"></use>
				        </mask>
				        <mask id="mask-6" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="191.650444" height="180.515735" fill="white">
				            <use xlink:href="#path-3"></use>
				        </mask>
				    </defs>
				    <g id="기본프사" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(60.000000, 0.000000)">
				        <mask id="mask-2" fill="white">
				            <use xlink:href="#path-1"></use>
				        </mask>
				        <use id="Mask" fill="#60C3C0" xlink:href="#path-1"></use>
				        <mask id="mask-5" fill="white">
				            <use xlink:href="#path-3"></use>
				        </mask>
				        <g id="Imported-Layers" stroke="#FFFFFF" mask="url(#mask-4)" stroke-width="8" opacity="0.7">
				            <use mask="url(#mask-6)" xlink:href="#path-3"></use>
				        </g>
				    </g>
				</svg>'
