###
USER = 
	email: ""
	displayName: ""
	photoURL: ""
	statusMsg: ""
	groups: []
	chats: []

CHAT = 
	title: ""
	lastMessage: ""
	lastMessageAt: ""
	createAt: ""

MEMBER = []

MESSAGES = 
	name: ""
	nickname: ""
	photo: ""
	message: ""
	timestamp: ""

GROUP = 
	name: ""
	members: []
###

class exports.User
	# 배열 : 유저 UUID
	@EMAIL = "email"
	@DISPLAY_NAME = "displayName"
	@PHOTO_URL = "photoURL"
	@STATUS_MSG = "statusMsg"
	@BG_URL = "bgURL"
	@GROUPS = "groups"
	@CHATS = "chats"

class exports.Chat
	# 배열 : 채팅 아이디
	@TITLE = "title"
	@LAST_MESSAGE = "lastMessage"
	@LAST_MESSAGE_AT = "lastMessageAt"
	@CREATE_AT = "createAt"

class exports.Member
	# 배열 : 멤버 아이디

class exports.Message
	@Type = {}
	@Type.Text = 'text'
	@Type.Image = 'image'

	# 배열 : 메시지 아이디
	@TYPE = "type"
	@CREATOR = "creator"
	@MESSAGE = "message"
	@CREATE_AT = "createAt"
	@READ = "read"

class exports.Group
	# 배열 : 그룹 아이디