ios = require 'ios-kit'
kakaotalk = require 'kakaotalk-kit'

# 리소스
r = (file) -> "images/#{file}"

### 
카카오톡 :: 빈 화면

@auther ho.s
@since 2016.09.12
###
class exports.Empty extends Layer
	# 이벤트
	Events.Link = "Empty.Link"

	# 생성자
	constructor: (options = {}) ->
		super options
		@props = width: @parent.width, height: @parent.height, backgroundColor: ""

		options.icon ?= '이모티콘 영역'
		options.title ?= "빈페이지 또는 에러상황"
		options.description ?= "이모티콘 사용으로 말랑말랑한 페이지 상황 설명 권장<br/>이모티콘 사용으로 말랑말랑 페이지 설명 권장"
		options.buttonLabel ?= undefined
		options.buttonLink ?= undefined

		@icon = options.icon
		@title = options.title
		@description = options.description
		@buttonLabel = options.buttonLabel
		@buttonlink = options.buttonLink

		# 아이콘
		vIcon = new ios.View
			name: ".icon"
			constraints:
				width: 150
				height: 150
				align: "center"
			backgroundColor: "transparent"
			superLayer: @
		
		img = ios.utils.svg @icon
		new ios.View 
			name: ".icon.icon"
			width: img.width
			height: img.height
			html: img.svg
			constraints: align: "center"
			backgroundColor: "transparent"
			superLayer: vIcon
		
		# 제목
		tTitle = new ios.Text
			name: ".title"
			constraints:
				top: [vIcon, 18]
				horizontalCenter:vIcon
			fontSize: "19"
			opacity: .85
			textAlign: "center"
			text: @title
			superLayer: @
		
		# 설명
		tDescription = new ios.Text
			name: ".description"
			constraints:
				top: [tTitle, 7]
				horizontalCenter:vIcon
			width: 270
			fontSize: "13"
			opacity: .50
			textAlign: "center"
			text: @description
			superLayer: @

		unless _.isUndefined(@buttonLabel) && _.isNull(@buttonLabel)
			# 버튼
			btnLink = new ios.View
				name: ".link"
				constraints:
					top: [tDescription, 26]
					horizontalCenter:vIcon
					width: 130
					height: 45
				backgroundColor: kakaotalk.R.colors.Y1
				borderRadius: ios.px(4)
				superLayer: @

			# 버튼 : 레이블
			btnLink.label = new ios.Text
				name: ".link.label"
				constraints: align: "center"
				height: btnLink.height
				text: @buttonLabel
				textAlign: "center"
				fontSize: 16
				opacity: .85
				superLayer: btnLink

			btnLink.constraints = { width: ios.pt(btnLink.label.width + (69 * 2)), align: "horizontal" }

			# 이벤트
			btnLink.onClick => @emit Events.Link, btnLink, @



	##############################################################
	## EVENT HELPERS

	onLink: (cb) -> @on Events.Link, cb