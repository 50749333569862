ios = require 'ios-kit'
kakaotalk = require 'kakaotalk-kit'

### 
카카오톡 :: 로딩

@auther ho.s
@since 2016.09.27
###
class exports.Loading extends Layer
	# 생성자
	constructor: (options = {}) ->
		super options
		@props = width: Screen.width, height: Screen.height, backgroundColor: "rgba(0,0,0,.6)"

		@html = "<style type='text/css'>
		.loader {
		  margin: 0px auto;
		  top: 45%; 
		  font-size: 10px;
		  position: relative;
		  text-indent: -9999em;
		  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
		  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
		  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
		  border-left: 1.1em solid #ffffff;
		  -webkit-transform: translateZ(0);
		  -ms-transform: translateZ(0);
		  transform: translateZ(0);
		  -webkit-animation: load8 1.1s infinite linear;
		  animation: load8 1.1s infinite linear;
		}
		.loader,
		.loader:after {
		  border-radius: 50%;
		  width: 10em;
		  height: 10em;
		}
		@-webkit-keyframes load8 {
		  0% {
		    -webkit-transform: rotate(0deg);
		    transform: rotate(0deg);
		  }
		  100% {
		    -webkit-transform: rotate(360deg);
		    transform: rotate(360deg);
		  }
		}
		@keyframes load8 {
		  0% {
		    -webkit-transform: rotate(0deg);
		    transform: rotate(0deg);
		  }
		  100% {
		    -webkit-transform: rotate(360deg);
		    transform: rotate(360deg);
		  }
		}
		</style>"

		@loader = document.createElement "div"
		@loader.classList.add("loader")
		@_element.appendChild @loader

		@loader

		#
		@onClick ->	console.log "click block"

	show: (delay = 0)-> 
		@visible = true
		Utils.delay(delay, -> @dismiss()) if delay != 0
	dismiss: ()-> @visible = false