ios = require 'ios-kit'

String.prototype.zf =  (len) -> return ("0" + this).slice(-len)
Number.prototype.zf = (len) -> return this.toString().zf(len)

### 
카카오톡 :: 유틸

@auther ho.s
@since 2016.09.19
###
class exports.Utils
	@sortBy: (collection, key, ascending = true) ->
		order = if ascending then 1 else -1
		collection.sort (a, b) ->
			[av, bv] = [a[key], b[key]]
			if av >= bv then 1 * order else if av <= bv then -1 * order else 0

	###
	Date를 문자로
	@params date [Date]  변환할 Date
	@return [String] 변환 문자열 (오전 9:12)
	###
	@dateToString: (date) -> "#{(if date.getHours() < 12 then "오전" else "오후")} #{if h = date.getHours() % 12 then h else 12}:#{date.getMinutes().zf(2)}"

	# svg 
	@svg = (svg, scaleWidth, scaleHeight) ->
		# Find String
		startIndex = svg.search("<svg width=")
		endIndex = svg.search(" viewBox")
		string = svg.slice(startIndex, endIndex)

		#Find width
		wStartIndex = string.search("=") + 2
		wEndIndex =  string.search("px")
		width = string.slice(wStartIndex, wEndIndex)
		scaleWidth ?= width
		newWidth = ios.px(scaleWidth)

		# Find Height
		heightString = string.slice(wEndIndex + 4, string.length)
		hStartIndex = heightString.search("=")+ 2
		hEndIndex = heightString.search("px")
		height = heightString.slice(hStartIndex, hEndIndex)
		scaleHeight ?= height
		newHeight = ios.px(scaleHeight)

		#Create new string
		newString = string.replace(width, newWidth)
		newString = newString.replace(height, newHeight)

		#Replace strings
		svg = svg.replace(string, newString)

		return {
			svg:svg
			width:newWidth
			height:newHeight
		}

	# Changes the fill and storke of an SVG
	@changeSVGColor: (layer, fillColor, strokeColor) ->
		parser = new DOMParser()
		doc = parser.parseFromString(layer.html, "image/svg+xml")
		paths = doc.querySelectorAll('path')
		# if _.isArray(paths) && !_.isEmpty(paths)
		for path in paths
			path.setAttribute("fill", fillColor)
			path.setAttribute("stroke", strokeColor)
		layer.html = (new XMLSerializer()).serializeToString(doc)
			# doc.querySelectorAll("path").forEach (path) -> 
			# 	path.setAttribute("fill", fillColor)
			# 	path.setAttribute("stroke", strokeColor)
			# layer.html = (new XMLSerializer()).serializeToString(doc)

	# 스티키 헤더
	@createStickyHeader: (label, scrollComponent, isFirst = false, isLast = false) ->

		header = new ios.View
			name: ".stickyHeader"
			constraints:
				trailing: 0
				leading: 0
				height: 25
			backgroundColor: "white"

		header.label = new ios.Text
			name: ".label"
			constraints:
				leading: 15
				align: "vertical"
			text: label
			fontSize: 12
			fontWeight: "light"
			color: "#8E8E93"
			superLayer: header

		if !isFirst
			header.linebottom = new ios.View
				name: ".linebottom"
				constraints:
					top: 0
					leading: 0
					trailing: 0
					height: 0.5
				backgroundColor: "#E6E6E6"
				superLayer: header

		if !isLast
			header.linetop = new ios.View
				name: ".linetop"
				constraints:
					leading: 0
					trailing: 0
					bottom: 0
					height: 0.5
				backgroundColor: "#E6E6E6"
				superLayer: header

		if scrollComponent?
			content = scrollComponent.content
			header.parent = content
			header.y = content.contentFrame().y + content.contentFrame().height

		return header 

	# 스틱키 해더 활성화
	@enableStickyHeader: (scrollComponent, fixedTopPos = 0, headerName = ".stickyHeader") ->

		_stickyHeaders = []
		_dataSH = []

		_stickyHeaders = scrollComponent.content.childrenWithName(headerName)

		if _stickyHeaders.length > 0
			_dataSH.push(layer.y) for layer, i in _stickyHeaders
				
		# 이벤트
		scrollComponent.content.on "change:y", ->
			if _stickyHeaders.length > 0
				for layer, i in _stickyHeaders

					layer.y  =  _dataSH[i]
					_thisY   =  _dataSH[i] - scrollComponent.scrollY
					_fixedY  =  _dataSH[i] + -_thisY + fixedTopPos

					if i is 0
						if _thisY <= fixedTopPos
							layer.y = _fixedY

					if i > 0
						_prevStickyPosition = _dataSH[i] - _stickyHeaders[i-1].height
						_prevMaxY = _stickyHeaders[i-1].height + fixedTopPos

						# Transition
						if _thisY < _prevMaxY
							_stickyHeaders[i-1].y = _prevStickyPosition

						# New Sticky
						if _thisY <= fixedTopPos
							layer.y = _fixedY