ios = require 'ios-kit'
kakaotalk = require 'kakaotalk-kit'

### 
카카오톡 :: 계정 :: 약관동의

@auther ho.s
@since 2016.09.21
###
class exports.SignUpTerms extends Layer
	Events.Checked = "checked"

	# 생성자
	constructor: (options = {}) ->
		super options
		@props = width: @parent.width, height: @parent.height, backgroundColor: kakaotalk.R.colors.Y1
		# main = @custom

		# 상태바
		# main.statusBar.theme = "dark"

		# 계정정보
		@account = options.account

		# 네비게이션바
		navBar = new ios.NavBar
			left: false
			title: "약관동의"
			right: false
			blur: false
			titleColor: "black"
			color: "rgba(255,255,255,.85)"
			backgroundColor: "transparent"
			dividerBackgroundColor: "#E5D019"
			superLayer: @

		div = new ios.View
			name: ".div"
			constraints: top: ios.pt((@parent.height - navBar.maxY) / 2 + navBar.maxY), leading: 0, trailing: 0, height: 0.5
			backgroundColor: "transparent"

		# 이용약관
		serviceTerms = _createBox { top: [navBar, 13], bottom: [div, 7] },
							'<u>이용약관</u>',
							'환영합니다!<br/>카카오 서비스를 이용해 주셔서 감사합니다. 다음카카오는 다양한 인터넷과 모바일 서비스(Application)를 여러분이 가장 편안하고 친근하게 이용할 수 있는 방법으로 제공하고 있습니다. 다음카카오는 여러분이 카카오 서비스에 더 가깝게 다가갈 수 있도록 카카오 계정을 기반으로 하는 모든 카카오 서비스에 적용될 수 있는 하나의 약관을 마련하였습니다. 여러분이 조금만 시간을 내서 약관을 읽어주신다면, 여러분과 다음카카오는 더욱 가까운 사이가 될 것이라고 믿습니다.',
							'동의합니다.'
		serviceTerms.parent = @
		
		# 개인정보
		privacyTerms = _createBox { top: [div, 7], bottom: 13 },
							'<u>개인정보 수집 및 이용안내</u>',
							'환영합니다!<br/>카카오 서비스를 이용해 주셔서 감사합니다. 다음카카오는 다양한 인터넷과 모바일 서비스(Application)를 여러분이 가장 편안하고 친근하게 이용할 수 있는 방법으로 제공하고 있습니다. 다음카카오는 여러분이 카카오 서비스에 더 가깝게 다가갈 수 있도록 카카오 계정을 기반으로 하는 모든 카카오 서비스에 적용될 수 있는 하나의 약관을 마련하였습니다. 여러분이 조금만 시간을 내서 약관을 읽어주신다면, 여러분과 다음카카오는 더욱 가까운 사이가 될 것이라고 믿습니다.',
							'동의하고 계속 진행합니다.'
		privacyTerms.parent = @

		console.log @account
		# 체크 확인
		onChecked = =>
			if serviceTerms.isChecked and privacyTerms.isChecked
				kakaotalk.Firebase.get().createUserWithEmailAndPassword(@account.email, @account.password)
				.then (user) => 
					new kakaotalk.SignUpProfile parent: @parent, home: @home
				, (error) => 
					(new ios.Alert title: "가입 오류", message: error.message, actions: ["확인"]).actions["확인"].onClick => @destroy()


		serviceTerms.on Events.Checked, onChecked
		privacyTerms.on Events.Checked, onChecked
	
	# 박스 생성
	_createBox = (constraints, title, contents, checkbox) ->
		box = new ios.View
			name: ".box"
			constraints:
				# top: [navBar, 13]
				leading: 13
				trailing: 13
				# bottom: [div, 7]
			backgroundColor: "white"
			borderRadius: ios.px(5)
			borderColor: 'rgba(235,216,0,1)'
			borderWidth: ios.px(0.5)
			# superLayer: @
		_.extend box.constraints, constraints

		box.title = new ios.Text
			name: ".box.title"
			constraints:
				top: 14
				leading: 12
			text: title
			fontSize: 17
			opacity: .85
			superLayer: box

		icArrow = ios.utils.svg '<svg width="18px" height="18.5px" viewBox="639 33 36 37" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
								    <!-- Generator: Sketch 40 (33762) - http://www.bohemiancoding.com/sketch -->
								    <desc>Created with Sketch.</desc>
								    <defs></defs>
								    <path d="M660.685373,55.39248 L645.477338,55.39248 C644.430956,55.39248 643.585786,56.2431184 643.585786,57.2924341 C643.585786,58.3490654 644.432663,59.1923882 645.477338,59.1923882 L662.59373,59.1923882 C663.115524,59.1923882 663.587284,58.9808623 663.929157,58.6386549 C664.275565,58.2935579 664.485281,57.8217913 664.485281,57.3008366 L664.485281,40.1844448 C664.485281,39.138063 663.634643,38.2928932 662.585327,38.2928932 C661.528696,38.2928932 660.685373,39.1397697 660.685373,40.1844448 L660.685373,55.39248 Z" id="Rectangle-109" stroke="none" fill="#B2B2B2" fill-rule="evenodd" transform="translate(654.035534, 48.742641) rotate(-45.000000) translate(-654.035534, -48.742641) "></path>
								</svg>'
		box.arrow = new ios.View
			name: ".box.arrow"
			constraints:
				top: 17
				trailing: 15
			width: icArrow.width
			height: icArrow.height
			html: icArrow.svg
			superLayer: box
			backgroundColor: "transparent"

		box.line = new ios.View
			name: ".box.line"
			constraints: leading: 0, trailing: 0, bottom: 44, height: 0.5
			backgroundColor: "#E6E6E6"
			superLayer: box

		box.check = new ios.View
			name: ".box.check"
			constraints:
				leading: 12
				bottom: 9
				width: 26
				height: 26
			backgroundColor: "rgba(135,135,135,.5)"
			borderRadius: "100%"
			superLayer: box

		icCheck = ios.utils.svg '<svg width="27px" height="19px" viewBox="13 16 27 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
								    <!-- Generator: Sketch 40 (33762) - http://www.bohemiancoding.com/sketch -->
								    <desc>Created with Sketch.</desc>
								    <defs></defs>
								    <path d="M23.0284199,33.67808 C23.3478669,33.6683648 23.6644201,33.541555 23.9083245,33.2976506 C24.4163698,32.790246 37.4781948,19.728421 37.98624,19.2203757 C38.4942853,18.7123304 38.4942853,17.8890792 37.98624,17.381034 C37.4781948,16.8729887 36.6543029,16.8729887 36.1462576,17.381034 C35.6710909,17.8562007 24.2149835,29.3123081 22.3185363,31.2087553 C21.8889946,29.4562768 16.6680819,24.2353641 16.2203757,23.7876579 C15.7123304,23.2796127 14.8890792,23.2796127 14.381034,23.7876579 C13.8729887,24.2957032 13.8729887,25.1195951 14.381034,25.6276404 C14.8890792,26.1356856 21.5436396,32.790246 22.0516849,33.2982913 C22.320229,33.5668354 22.6768417,33.6934317 23.0284199,33.67808 Z" id="Fill-1-Copy" stroke="#FFFFFF" stroke-width="2" fill="#FFFFFF" fill-rule="evenodd"></path>
								</svg>'
		box.check.icon = new ios.View
			name: ".box.check.icon"
			constraints: align: "center"
			width: icCheck.width
			height: icCheck.height
			html: icCheck.svg
			backgroundColor: "transparent"
			scale: 1/ios.device.scale
			superLayer: box.check
		box.check.icon.center()

		box.check.label = new ios.Text
			name: ".box.check.icon"
			constraints: 
				leading: [box.check, 10]
				verticalCenter: box.check
			text: checkbox
			fontSize: 16
			opacity: .85
			superLayer: box

		box.content = new ScrollComponent
			x: ios.px(12), y: box.title.maxY + ios.px(13)
			width: box.width - (ios.px(12) * 2), height: box.height - ios.px(box.line.constraints.bottom) - (box.title.maxY + ios.px(13)) - ios.px(13)
			scrollHorizontal: false
			parent: box

		box.content.contents = new ios.Text
			constraints:
				width: ios.pt(box.content.content.width)
			text: contents
			fontSize: 13
			lineHeight: 16
			opacity: .5
			superLayer: box.content.content
		box.content.updateContent()

		box.isChecked = false
		box.check.onClick ->
			box.isChecked = !box.isChecked
			if box.isChecked
				box.check.backgroundColor = kakaotalk.R.colors.Y1
				kakaotalk.Utils.changeSVGColor box.check.icon, "#4B2F30", "#4B2F30"
			else
				box.check.backgroundColor = "rgba(135,135,135,.5)"
				kakaotalk.Utils.changeSVGColor box.check.icon, "#FFFFFF", "#FFFFFF"

			box.emit Events.Checked, box.isChecked, box

		box

