### 
소개 테마 - Image
@auther ho.s
@since 2016.07.07
@version 0.2
###

{IntroTheme} = require "IntroTheme"
class IntroThemeImage extends IntroTheme
	# CSS
	_cssFont = "@import url(http://fonts.googleapis.com/earlyaccess/notosanskr.css); font-family: 'Noto Sans KR', serif, sans-serif;"
	Utils.insertCSS(_cssFont)

	# 생성자
	constructor: ->
		super 
			# 이름
			name: 'image'
			# 프로젝트 제목
			title: 
				style:
					color: "#FFF"
					, font: "100 160px/100px Noto Sans KR"
					, textAlign: "center"
					, opacity: "0.7"
			# 프로젝트 세부 제목
			subTitle: 
				style:
					color: "#FFF"
					, font: "500 130px/100px Noto Sans KR"
					, textAlign: "center"
					, opacity: "0.6" 
			# 상세 정보
			details: 
				style: 
					color: "#FFF"
					, font: "200 50px/80px Noto Sans KR"
					, textAlign: "center"
					, opacity: "0.8"
			# back 버튼
			back: invert: 100
			# 메이커 CI
			ci: brightness: 200, saturate: 0
			# 프레이머 로고
			logo: brightness: 200, saturate: 0
			# 배경
			background: image: "images/intro/theme/image/bg.jpg", blur: 20, opacity: 0.7

		#
		Framer.Device.background.props = backgroundColor: "rgba(0,0,0,1)"

	
exports.IntroThemeImage = IntroThemeImage