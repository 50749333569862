ios = require 'ios-kit'
kakaotalk = require 'kakaotalk-kit'

### 
카카오톡 :: 스플래시

@auther ho.s
@since 2016.09.12
###
class exports.Splash extends Layer

	# 생성자
	constructor: (options = {}) ->
		super options

		@props = width: Screen.width, height: Screen.height, backgroundColor: kakaotalk.R.colors.Y1
		@icon = new Layer x: Align.center, y: Align.center, width: 208, height: 193, image: "images/splash_image.png", scale: ios.device.scale / 2, parent: @


	dismiss: ->
		@animate properties: { opacity: 0 }, time: .3, delay: 1
		@onAnimationDidEnd -> @destroy()