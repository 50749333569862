### 
소개 테마 - White
@auther ho.s
@since 2016.07.07
@version 0.2
###

{IntroTheme} = require "IntroTheme"
class IntroThemeWhite extends IntroTheme
	# CSS
	_cssFont = "@import url(http://fonts.googleapis.com/earlyaccess/notosanskr.css); font-family: 'Noto Sans KR', serif, sans-serif;"
	Utils.insertCSS(_cssFont)

	# 생성자
	constructor: ->
		super 
			# 이름
			name: 'white'
			# 프로젝트 제목
			title: 
				style:
					color: "#333"
					, font: "100 160px/100px Noto Sans KR"
					, textAlign: "center"
					, opacity: "0.7"
			# 프로젝트 세부 제목
			subTitle: 
				style:
					color: "#333"
					, font: "500 130px/100px Noto Sans KR"
					, textAlign: "center"
					, opacity: "0.6" 
			# 상세 정보
			details: 
				style: 
					color: "#333"
					, font: "200 50px/80px Noto Sans KR"
					, textAlign: "center"
					, opacity: "0.8"
			# 메이커 CI
			ci: brightness: 100, saturate: 100, hueRotate: 0, contrast: 100, invert: 0, grayscale: 0, sepia: 0
			# 프레이머 로고
			logo: brightness: 100, saturate: 100, hueRotate: 0, contrast: 100, invert: 0, grayscale: 0, sepia: 0
			# 배경
			background: { backgroundColor: 'white' }
	
exports.IntroThemeWhite = IntroThemeWhite