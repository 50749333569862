# 리소스
getResource = (file) -> "images/#{file}"

### 
카카오톡

@auther ho.s
@since 2016.09.02
###
class exports.KakaoTalkLayer extends Layer

	# 크기
	SIZE = 
		FRAME: WIDTH: 750, HEIGHT: 1334
		STATUS: WIDTH: 750, HEIGHT: 40
		HEADER:	WIDTH: 750, HEIGHT: 90

	##############################################################
	## 속성

	# 화면비율
	@define "ratio", get: -> Screen.width / SIZE.FRAME.WIDTH

	# 생성자
	constructor: (options = {}) ->
		super options
		
		# 속성
		@props = name: "KakaoTalk", width: SIZE.FRAME.WIDTH, height: SIZE.FRAME.HEIGHT, backgroundColor: "", scale: @ratio, originX: 0, originY: 0

		# 상단
		@_top = new Layer name: "top", width: @width, height: SIZE.STATUS.HEIGHT + SIZE.HEADER.HEIGHT, backgroundColor: "", parent: @

		# 상단 :: 상태바
		if Utils.isDesktop() then new Layer name: "statusbar", width: @width, height: SIZE.STATUS.HEIGHT, image: getResource("statusbar.png"), backgroundColor:"", parent: @_top
		# 상단 :: 헤더
		@_header = new Layer name: "header", y: SIZE.STATUS.HEIGHT, width: @width, height: SIZE.HEADER.HEIGHT, backgroundColor:"", parent: @_top
