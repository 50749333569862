# ios 모듈
ios = require 'ios-kit'
# kakaotalk 모듈
kakaotalk = require 'kakaotalk-kit'

# 리소스
getResource = (file) -> "images/#{file}"

# 힌트안보기
Framer.Extras.Hints.disable()

# 홈버튼
if Utils.isDesktop() and Framer.Device.deviceType.indexOf("iphone") isnt -1
	Events.Home = "home"

	size = 120;	x = 0; y = 770;
	# b = -100 * scale
	if Framer.Device.deviceType.indexOf("5") isnt -1
		y = parseInt(770 * (640 / 750) + 22)
	else if Framer.Device.deviceType.indexOf("plus") isnt -1
		x = 5
		y = parseInt(770 * (1242 / 750) + 10)
		size = 180

	Framer.Device.phone.home = homeBtn = new Layer
		x: Align.center(x), y: Align.center(y)
		size: size
		borderRadius: size / 2
		borderColor: "#665D0A"
		borderWidth: 0
		backgroundColor: "rgba(255,235,0,.8)"
		html: "Sign Out"
		style:
			font: "400 #{size*0.2}px/#{size}px #{Utils.deviceFont()}"
			textAlign: "center"
			letterSpacing: "-1.5px"
		color: "#493334"
		visible: false
		parent: Framer.Device.phone
		
	homeBtn.onClick -> @emit Events.Home, @
	# homeBtn.onDoubleClick -> print "DOUBLE CLICK"

	Framer.Device.phone.onHome = (cb) -> homeBtn.on Events.Home, cb
	Framer.Device.phone.home.show = -> Framer.Device.phone.home.visible = true

### 
카카오톡

@auther ho.s
@since 2016.09.12
###
class exports.KakaoTalk extends Layer

	# 생성자
	constructor: (options = {}) ->
		super options
		@backgroundColor = ""

		# 배경
		Screen.backgroundColor = "white"

		# 탭 : 친구
		@friend = new ios.Tab
			label: "친구"
			icon: '<svg width="25px" height="25px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					    <!-- Generator: Sketch 39.1 (31720) - http://www.bohemiancoding.com/sketch -->
					    <desc>Created with Sketch.</desc>
					    <defs></defs>
					    <path d="M44.213,38.5300883 C40.581,37.3490883 35.619,37.0240883 32.575,35.9870883 L33.457,35.9870883 C32.717,35.6480883 32.153,35.2790883 31.875,34.8360883 C31.007,33.4500883 30.871,32.0210883 31.06,30.4040883 C32.876,28.9330883 33.533,25.7080883 33.796,24.4060883 C35.639,24.9560883 35.519,21.9840883 36.239,20.5780883 C37.717,17.6950883 36.935,16.1320883 35.144,16.4910883 C35.129,16.5580883 35.119,16.6220883 35.104,16.6890883 C35.103,16.6990883 35.102,16.4950883 35.102,16.4950883 C37.927,3.78208831 28.892,-1.14991169 28.918,0.222088314 C28.958,2.37408831 27.808,2.87008831 22.578,4.00708831 C17.04,5.21108831 15.028,8.19508831 14.511,11.4370883 C14.095,14.0560883 14.913,16.4950883 14.913,16.4950883 C13.091,16.1000883 12.286,17.6710883 13.776,20.5780883 C14.497,21.9840883 14.375,24.9560883 16.22,24.4060883 C16.482,25.7080883 17.092,28.9330883 18.908,30.4040883 C19.097,32.0210883 18.909,33.4500883 18.04,34.8360883 C17.763,35.2790883 17.587,35.6480883 16.848,35.9870883 L17.431,35.9870883 C14.388,37.0240883 9.426,37.3490883 5.794,38.5300883 C0.008,40.4110883 0.008,46.8440883 0.008,46.8440883 L0,49.0200883 L50,49.0200883 L49.999,46.8440883 C49.999,46.8440883 49.999,40.4110883 44.213,38.5300883 Z" id="Imported-Layers" stroke="none" fill="#CECECE" fill-rule="evenodd"></path>
					</svg>'
		
		# 탭 : 채팅
		@chatting = new ios.Tab
			label: "채팅"
			icon: '<svg width="25.5px" height="23.5px" viewBox="0 0 51 47" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					    <!-- Generator: Sketch 39.1 (31720) - http://www.bohemiancoding.com/sketch -->
					    <desc>Created with Sketch.</desc>
					    <defs></defs>
					    <path d="M10.932,35.973 C10.932,35.973 10.504,38.423 9.198,44.996 C8.979,45.223 9.161,45.781 9.437,45.947 C9.795,46.162 10.2,45.811 10.381,45.75 C12.158,45.158 15.732,42.461 20.446,39.058 C21.921,39.271 23.442,39.384 24.996,39.384 C38.801,39.384 49.992,30.567 49.992,19.691 C49.992,8.816 38.801,0 24.996,0 C11.192,0 0,8.816 0,19.691 C0,26.458 4.333,32.428 10.932,35.973" id="Imported-Layers" stroke="none" fill="#493334" fill-rule="evenodd"></path>
					</svg>'

		# 탭 : 채널
		@channel = new ios.Tab
			label: "채널"
			icon: '<svg width="24px" height="23px" viewBox="0 0 48 46" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					    <!-- Generator: Sketch 39.1 (31720) - http://www.bohemiancoding.com/sketch -->
					    <desc>Created with Sketch.</desc>
					    <defs></defs>
					    <path d="M42,0 L6,0 C2.686,0 0,2.686 0,6 L0,40 C0,43.314 2.686,46 6,46 L42,46 C45.314,46 48,43.314 48,40 L48,6 C48,2.686 45.314,0 42,0 L42,0 Z M6,6 L42,6 L42,40 L6,40 L6,6 Z M10,21 L22,21 L22,10 L10,10 L10,21 Z M10,36 L22,36 L22,25 L10,25 L10,36 Z M26.5,14 L37.5,14 C38.328,14 39,13.328 39,12.5 C39,11.672 38.328,11 37.5,11 L26.5,11 C25.672,11 25,11.672 25,12.5 C25,13.328 25.672,14 26.5,14 L26.5,14 Z M26.5,21 L37.5,21 C38.328,21 39,20.328 39,19.5 C39,18.672 38.328,18 37.5,18 L26.5,18 C25.672,18 25,18.672 25,19.5 C25,20.328 25.672,21 26.5,21 L26.5,21 Z M26.5,28 L37.5,28 C38.328,28 39,27.328 39,26.5 C39,25.672 38.328,25 37.5,25 L26.5,25 C25.672,25 25,25.672 25,26.5 C25,27.328 25.672,28 26.5,28 L26.5,28 Z M26.5,35 L37.5,35 C38.328,35 39,34.328 39,33.5 C39,32.672 38.328,32 37.5,32 L26.5,32 C25.672,32 25,32.672 25,33.5 C25,34.328 25.672,35 26.5,35 L26.5,35 Z" id="Imported-Layers" stroke="none" fill="#CECECE" fill-rule="evenodd"></path>
					</svg>'

		# 탭 : 더보기
		@more = new ios.Tab
			label: "더보기"
			icon: '<svg width="25px" height="7px" viewBox="1 0 50 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					    <!-- Generator: Sketch 39.1 (31720) - http://www.bohemiancoding.com/sketch -->
					    <desc>Created with Sketch.</desc>
					    <defs></defs>
					    <path d="M8,14 C11.8659932,14 15,10.8659932 15,7 C15,3.13400675 11.8659932,0 8,0 C4.13400675,0 1,3.13400675 1,7 C1,10.8659932 4.13400675,14 8,14 Z M26,14 C29.8659932,14 33,10.8659932 33,7 C33,3.13400675 29.8659932,0 26,0 C22.1340068,0 19,3.13400675 19,7 C19,10.8659932 22.1340068,14 26,14 Z M44,14 C47.8659932,14 51,10.8659932 51,7 C51,3.13400675 47.8659932,0 44,0 C40.1340068,0 37,3.13400675 37,7 C37,10.8659932 40.1340068,14 44,14 Z" id="Oval-25" stroke="none" fill="#CECECE" fill-rule="evenodd"></path>
					</svg>'
		
		# 탭바
		tabBar = new ios.TabBar 
			tabs: [@friend, @chatting, @channel, @more]
			activeColor: "#493334"
			inactiveColor: "#CECECE"
			backgroundColor: "#F7F7F7"

		# 탭 뷰 사이즈 조정
		tabViewConstraints = top:0, bottom: ios.pt(tabBar.height)
		for tab in tabBar.tabs
			tab.view.constraints = tabViewConstraints
			ios.layout.set()

		# new window
		@injectView = new ios.View
			name: "injectView"
			constraints:
				top: 0
				trailing: 0
				bottom: 0
				leading: 0
			backgroundColor: "transparent"
		@injectView.visible = false

		@injectView.on "change:children", ->
			@visible = not _.isEmpty(@)

		# 상태바
		@statusBar = new kakaotalk.Status theme: "light"
		
		# 스플래시
		splash = new kakaotalk.Splash

		# Firebase 초기화
		kakaotalk.Firebase.get ($) =>
			account = kakaotalk.Storage.get().getAccount()
			# 저장된 계정이 없으면 -> 가입
			if _.isUndefined(account)
				# 가입
				# SignUp = new kakaotalk.SignUp
				# 	parent: injectView
				# 	custom: this
				@launchInjectView kakaotalk.SignUp
			# 저장된 계정이 있으면 -> 로그인
			else
				# kakaotalk.Firebase.get().signOut()
				kakaotalk.Firebase.get().signInWithEmailAndPassword(account.email, account.password)
				.then (user) => @_createTabView()

			#
			splash.dismiss()


		# 홈 버튼
		Framer.Device.phone.onHome @_signOut if Utils.isDesktop()
			

	#
	launchInjectView: (prototype, options) =>
		new prototype _.extend({ parent: @injectView, home: this }, options)
		@injectView.visible = true
		
	# 가입 / 로그인 완료
	completeSingUp: ()->
		# 상태바
		@statusBar.theme = "light"
		# 탭뷰생성
		@_createTabView()

		#
		console.log "User : #{Utils.stringify(kakaotalk.Firebase.get().user)}"

	# 로그아웃
	_signOut: ()->
		# 저장된 계정 정보 삭제
		kakaotalk.Storage.get().clearAccount()
		# 로그아웃
		kakaotalk.Firebase.get().signOut()
		#
		Utils.reset()
		#
		window.location.reload()

	# 탭뷰 생성
	_createTabView: () =>
		# 탭뷰 설정
		new kakaotalk.Friend parent: @friend.view, home: this
		new kakaotalk.Chatting parent: @chatting.view, home: this
		new kakaotalk.Channel parent: @channel.view, home: this
		new kakaotalk.More parent: @more.view, home: this

		# 홈 버튼 보이기
		Framer.Device.phone.home.show() if Utils.isDesktop()