# Import
ios = require 'ios-kit'
kakaotalk = require 'kakaotalk-kit'
moment = require "moment"

### 
카카오톡 :: 대화 :: 날짜 구분선 

@auther ho.s
@since 2016.09.02
###
class exports.MessageDateDivider extends Layer

	# 요일
	weekName = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"]

	@define 'date',
		get: -> @__date
		set: (value = new Date().toString()) -> 
			@__date = value
			_moment = moment(value)
			@_dateString = _moment.format("YYYY년 M월 D일 ") + weekName[_moment.day()]  
			@_changeDate @_dateString

	# 생성자
	constructor: (options={}) ->
		super options

		# 속성
		@props = name:"DateDivider", x: 12, width: 750 - (12 * 2), height: 28 + (18 * 2), backgroundColor: "transparent"

		# 추가 속성
		options.date ?= new Date().toString()
		@date = options.date

		# 날짜 요일 정보
		kakaotalk.MessageHelper.textAutoSize @_date = new Layer name: "date", x: Align.center, y: Align.center, height: 28, html:@_dateString, style: { font: "Normal 22px/28px #{Utils.deviceFont()}" }, color: "black", opacity: .5, backgroundColor: "transparent", parent: @
		@_date.width = parseInt(@_date.style.width) + 1
		@_date.x = Align.center
		
		# 양쪽 라인
		@_lineLeft = new Layer name: "lineLeft", y: Align.center, height: 1, width: (@width - @_date.width) / 2 - 10, parent: @, backgroundColor: "#708696"
		@_lineRight = new Layer name: "lineRight", x: @_date.x + @_date.width + 10, y: Align.center, height: 1, width: (@width - @_date.width) / 2 - 10, parent: @, backgroundColor: "#708696"

		# print @_date

	_changeDate: (dateString) -> 
		if @_date?
			@_date.html = dateString 
			kakaotalk.MessageHelper.textAutoSize @_date

