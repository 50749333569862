ios = require 'ios-kit'
kakaotalk = require 'kakaotalk-kit'

### 
카카오톡 :: 인풋 :: 파일

@auther ho.s
@since 2016.10.06
###
class exports.File extends Layer

	Events.Change = "change"
	Events.Preview = "preview"

	# 생성자
	constructor: (options = {}) ->
		options.backgroundColor ?= "transparent"
		super options

		@preview = options.preview

		file = undefined
		metadata = undefined

		input = document.createElement "input"
		input.name = ".file"
		input.type = "file"
		input.accept = "image/*"
		# 파일 : 스타일
		_.extend input.style,
			font: "400 #{@height}px/#{@height}px #{Utils.deviceFont()}"
			# outline: "none"
			backgroundColor: "transparent"
			height: "100%"
			width:  "100%"
			# pointerEvents: "none"
			cursor: "pointer"
			margin: "0"
			padding: "0"
			borderRadius: "100%"
			opacity: "0"
			"-webkit-appearance": "none"
		@_element.appendChild input

		# 파일 : 이벤트
		Events.wrap(input).addEventListener "change", (event) =>
			event.stopPropagation()
			event.preventDefault()
			
			file = event.target.files[0]
			# metadata = 'contentType': file.type

			# 이벤트 : 변경
			@emit Events.Change, file, @
			
			reader = new FileReader()
			reader.readAsDataURL(file)
			
			# 프리뷰
			reader.onload = =>
				tempImage = new Image()
				tempImage.src = reader.result 
				tempImage.onload = =>
					canvas = document.createElement('canvas')
					canvasContext = canvas.getContext("2d")
					
					canvas.width = tempImage.width
					canvas.height = tempImage.height
					
					canvasContext.drawImage(tempImage, 0, 0, tempImage.width, tempImage.height)
					
					dataURI = canvas.toDataURL("image/jpeg")

					# 프리뷰
					if @preview
						@preview.html = ''
						@preview.image = dataURI

					# 이벤트 : 프리뷰
					@emit Events.Preview, canvas, dataURI, @

	# EVENT HELPER
	onChange: (cb) -> @on Events.Change, cb
	onPreview: (cb) -> @on Events.Preview, cb