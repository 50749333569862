ios = require 'ios-kit'
kakaotalk = require 'kakaotalk-kit'

### 
카카오톡 :: 상태바

@auther ho.s
@since 2016.09.22
###
class exports.StatusBar extends Layer

	@define 'theme',
		get: -> @_theme
		set: (value = "dark") -> @_theme = value; @_changeTheme()

	# 생성자
	constructor: (options = {}) ->
		super options
		@props = backgroundColor: ""
		@constraints = leading: 0, trailing: 0, height: 20
		ios.layout.set()

		options.theme ?= "dark"
		@theme = options.theme

		@_create()

	_create: =>
		@statusBar = new ios.StatusBar
			signal: 3
			carrier: "KakaoTalk"
			battery: 70
			style: @theme
			superLayer: @

	_changeTheme: ->
		if @statusBar?
			child.destroy() for child in @children
			@_create()