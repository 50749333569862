ios = require 'ios-kit'
kakaotalk = require 'kakaotalk-kit'

# 리소스
r = (file) -> "images/#{file}"

### 
카카오톡 :: 빈 화면

@auther ho.s
@since 2016.09.12
###
class exports.SearchBar extends Layer

	# 생성자
	constructor: (options = {}) ->
		super options
		@props = width: @parent.width, height: ios.px(44), backgroundColor: "#EEEEEE"

		#
		options.placeholder ?= undefined

		#
		@placeholder = options.placeholder
		
		# 입력
		input = new ios.View
			name: ".input"
			constraints:
				top: 7
				trailing: 8
				bottom: 7
				leading: 8
			backgroundColor: "white"
			borderRadius: 5
			superLayer: @

		wrap = new ios.View
			constraints:
				top: 0
				trailing: 0
				bottom: 0
				leading: 0
			constraints: align: "center"
			backgroundColor: "transparent"
			superLayer: input
		
		# 아이콘
		icSearch = ios.utils.svg '<svg width="13px" height="13px" viewBox="234 33 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
						    <!-- Generator: Sketch 39.1 (31720) - http://www.bohemiancoding.com/sketch -->
						    <desc>Created with Sketch.</desc>
						    <defs></defs>
						    <path d="M250.000418,51.0004182 C248.329026,52.2559863 246.2514,53 244,53 C238.477153,53 234,48.5228475 234,43 C234,37.4771525 238.477153,33 244,33 C249.522847,33 254,37.4771525 254,43 C254,45.2513996 253.255986,47.3290255 252.000418,49.0004182 L260,57 L258,59 L250.000418,51.0004182 Z M244,51 C248.418278,51 252,47.418278 252,43 C252,38.581722 248.418278,35 244,35 C239.581722,35 236,38.581722 236,43 C236,47.418278 239.581722,51 244,51 Z" id="Search-Icon-Copy" stroke="none" fill="#8E8E93" fill-rule="evenodd"></path>
						</svg>'
		input.searchIcon = new ios.View
			name: ".input.icon"
			width: icSearch.width
			height: icSearch.height
			constraints: 
				height: 13
				width: 13
				align: "vertical"
			html: icSearch.svg
			backgroundColor: "transparent"
			superLayer: wrap

		# 레이블
		input.label = new ios.Text
			name: ".input.label"
			constraints: align: "vertical"
			x: input.searchIcon.maxX + 8
			text: @placeholder
			fontWeight: "light"
			fontSize: 14
			color: "#8E8E93"
			superLayer: wrap
			backgroundColor: "transparent"

		wrap.frame = wrap.contentFrame()
		wrap.center()
		ios.layout.set()