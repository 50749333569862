ios = require 'ios-kit'
kakaotalk = require 'kakaotalk-kit'

### 
카카오톡 :: 가입

@auther ho.s
@since 2016.09.21
###
class exports.SignUp extends Layer
	# 생성자
	constructor: (options = {}) ->
		super options
		@props = width: @parent.width, height: @parent.height, backgroundColor: kakaotalk.R.colors.Y1
		@parent.visible = true
		@home = options.home

		# 상태바
		@home.statusBar.theme = "dark"

		#
		new kakaotalk.SignUpAccount parent:@, home: @home

	# 완료
	complete: ()->
		@home.completeSingUp()
		@destroy()

		
