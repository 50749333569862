# Import
ios = require 'ios-kit'
kakaotalk = require 'kakaotalk-kit'

# 리소스
r = (file) -> "images/chatting/message/#{file}"

### 
카카오톡 :: 대화 :: 메시지

@auther ho.s
@since 2016.09.02
###
class exports.Message extends Layer

	# 메시지 타입
	@Type:
		# 보낸 메시지
		Send: "typeSend"
		# 받은 메시지
		Receive: "typeReceive"
	
	# 생상
	COLOR = 
		BALLOON:
			YOU: "white"
			ME: "#FFEB00"
				

	##############################################################
	## 속성

	###
	# 메시지 타입 / Message.Type / Message.Type.Send
	Message.Type.Send - 보낸메시지
	Message.Type.Receive - 받은 메시지
	###
	@define 'type',
		get: -> @_type
		set: (value = Message.Type.Send) -> @_type = value

	# 사용자 정보 / Object / { name: "이름", photo: "사진경로" }
	@define 'user',
		get: -> @_user
		set: (value = { displayName: "사용자", photoURL: r("profile.png") }) -> @_user = value

	# 메시지 / String / ""
	@define 'message',
		get: -> @_message
		set: (value = '') -> 
			if !@_message? 
				@_message = value;
			else 
				@_message = value;
				@reset()

	# 일시 / Date / new Date()
	@define 'at',
		get: -> @_at
		set: (value = undefined) -> @_at = value
	
	# 신규여부 / Boolean / true
	@define 'isNew',
		get: -> @_isNew
		set: (value = true) -> @_isNew = value

	# 말풍선 색상
	@define 'balloonColor',
		get: -> @_balloonColor
		set: (value = if @type == Message.Type.Send then COLOR.BALLOON.ME else COLOR.BALLOON.YOU) -> @_balloonColor = value; @changeBalloonColor value	

	# 메시지 색상
	@define 'textColor',
		get: -> @_textColor
		set: (value = "black") -> @_textColor = value; @changeTextColor value

	# 메시지 폰트 크기
	@define 'fontSize',
		get: -> @_fontSize
		set: (value = "30") -> @_fontSize = value; @reset()

	# 안 읽음 수
	@define 'unreadCount',
		get: -> @_unreadCount
		set: (value = 1) -> @_unreadCount = value; @changeUnreadCount value

	# 메시지 아이디
	@define 'messageId',
		get: -> @_messageId
		set: (value) -> @_messageId = value

	# 생성자
	constructor: (options={}) ->
		super options

		# 속성
		@props = name: "Message", x: 13, width: 750 - (13 * 2), backgroundColor: "transparent"

		## 추가 속성
		options.isNew ?= true
		options.at ?= new Date()
		options.readCount ?= 1

		@type = options.type
		@user = options.user
		@at = options.at
		@isNew = options.isNew
		@message = options.message
		@readCount = options.readCount

		@textColor = "black"

		# @_create()
	
	_create: =>
		@.y = 10

		# 상대방 메시지
		if @type == Message.Type.Receive
			
			# 사진
			user = new Layer width: 66, height: 66, image: @user.photoURL, borderRadius: "100%", backgroundColor:"white", parent: @
			# 이름
			name = new Layer x:85, y:4, html: @user.displayName, style: { font: "600 24px/24px #{Utils.deviceFont()}", color: "black" }, opacity: .7, backgroundColor: "", parent: @
			# 말풍선
			wh = Math.cos(45 * Math.PI / 180) * 50
			@.tail = new Layer x: 25, y: -25, width: wh, height: wh, backgroundColor: COLOR.BALLOON.YOU, rotation: 45, originX:0, originY: 0, borderRadius: 5, parent: new Layer name: "tail", x: 73, y: 47, width: 50, height: 25, backgroundColor:"", clip: true, parent: @
			# 자동크기 + 말풍선
			kakaotalk.MessageHelper.textAutoSize @.balloon = new Layer name: "balloon", x: 85, y: 40, backgroundColor: COLOR.BALLOON.YOU, borderRadius: 4, html: @message, style: { font: "Normal #{@fontSize}px/35px #{Utils.deviceFont()}", color: @textColor, wordBreak:"break-all", padding: "21px 15px"}, parent: @
			if parseInt(@.balloon.style.width) > 510 then kakaotalk.MessageHelper.textAutoSize @.balloon, {}, { width: 510 }
			@.balloon.width = parseInt(@.balloon.style.width) + 1
			@.balloon.x = 85
			@.balloon.y = 40
			
			# 연속 메시지
			if !@isNew
				@.y = 0
				@.balloon.y = 0
				user.visible = name.visible = @.tail.visible = false
			
			# 메시지 사이즈
			@size = width: parseInt(@.balloon.style.width) + 85, height: parseInt(@.balloon.style.height) + @.balloon.y	
			# 날짜
			# d = if @date? then new Date(@date) else new Date()
			kakaotalk.MessageHelper.textAutoSize @date = new Layer name: "date", x: parseInt(@.balloon.style.width) + 85 + 9, y: Align.bottom, height: 24, html: kakaotalk.MessageHelper.dateToString(@at), style: { font: "Normal 20px/20px #{Utils.deviceFont()}", color: "black" }, opacity: .5, backgroundColor:"", parent: @
			
		# 내 메시지
		else
			# 말풍선
			wh = Math.cos(45 * Math.PI / 180) * 50
			@.tail = new Layer name: ".tail", x: 25, y: -25, width: wh, height: wh, backgroundColor: COLOR.BALLOON.ME, rotation: 45, originX:0, originY: 0, borderRadius: 5, parent: new Layer name: "tail", x: 0, y: 7, width: 50, height: 25, backgroundColor:"", clip: true, parent: @
			# 자동크기 + 말풍선
			kakaotalk.MessageHelper.textAutoSize @.balloon = new Layer name: "balloon", backgroundColor: COLOR.BALLOON.ME, borderRadius: 4, html: @message, style: { font: "Normal #{@fontSize}px/1.25 #{Utils.deviceFont()}", color: @textColor, wordBreak:"break-all", padding: "21px 15px", whiteSpace: "pre-line"}, parent: @
			if parseInt(@.balloon.style.width) > 510 then kakaotalk.MessageHelper.textAutoSize @.balloon, {bottom:15}, { width: 510 }
# 			print balloon.style.height
			@.balloon.height = parseInt(@.balloon.style.height)
			@.balloon.width = parseInt(@.balloon.style.width) + 1
			@.balloon.x = 0
			# balloon.x = Align.right(-17)
			@.balloon.y = 0
			
			# 연속 메시지
			unless @isNew
				@.y = 0
				@.tail.visible = false
		
			# 메시지 사이즈
			@size = width: parseInt(@.balloon.style.width) + 12, height: parseInt(@.balloon.style.height) + @.balloon.y
			#말풍선 위치
			@x = 750 - @width - 20
			# 말풍선 꼬리 위치
			@.tail.parent.x = @width - @.tail.parent.width

			# 날짜
			# d = if @date? then new Date(@date) else new Date()
			kakaotalk.MessageHelper.textAutoSize @date = new Layer name: "date", height: 24, html: kakaotalk.MessageHelper.dateToString(@at), style: { font: "Normal 20px/20px #{Utils.deviceFont()}", color: "black", textAlign: "right" }, opacity: .5, backgroundColor:"transparent", parent: @
			@date.height = parseInt(@date.style.height) + 1
			@date.width = parseInt(@date.style.width) + 1
			@date.x = @.balloon.x - @date.width - 9
			@date.y = @.balloon.height - @date.height

			@date.on "change:html", =>
				@date.visible = not _.isEmpty @date.html

			@unread = new Layer
				name: "unread"
				height: 20
				html: "1"
				style:
					font: "500 20px/20px Helvetica Neue"
					textShadow: "1px 1px 0 rgba(0,0,0,.19)"
				color: "#FFEB00"
				backgroundColor: "transparent"
				parent: @
			kakaotalk.MessageHelper.textAutoSize @unread
			@unread.maxX = @date.maxX
			@unread.maxY = @date.y - 3

			@unread.on "change:html", =>
				console.log "change:html : unread", @unread.html + " / " + @date.visible
				@unread.visible = @unreadCount > 0
				@unread.maxY = @.balloon.height - 3 unless @date.visible

		# 여백
		@height += 10

	###
	날짜 변경
	@params v (String) 
	###
	changeDate: (v) -> 
		@date.html = v if @date

		# 읽음 수 위치 조정
		@unreadCount = @unreadCount if _.isEmpty v

	# 말풍선 색상 변경
	changeBalloonColor: (color) ->
		if @.balloon? && @.tail
			@.balloon.backgroundColor = @.tail.backgroundColor = color

	# 메시지 색상 변경
	changeTextColor: (color) -> @.balloon.color = color if @.balloon?

	# 메시지 내용 변경
	changeMessage: (message) ->
		@message = message
		@reset()

	# 안읽은 개수
	changeUnreadCount: (count) ->
		@unread.html = count if @unread

	# 다시 그리기
	reset: ->
		child.destroy() for child in @children
		@_create()

		@balloonColor = @balloonColor if @balloonColor?