### 
카카오톡 :: 대화 :: 메시지 헬퍼

@auther ho.s
@since 2016.09.02
###
class exports.MessageHelper
	#
	# Date.prototype.format = (f) ->
	# 	if !this.valueOf() then return " "
	# 	
	# 	weekName = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"]
	# 	d = this
	# 	
	# 	return f.replace "/(yyyy|yy|MM|dd|E|hh|mm|ss|a\/p)/gi", ($1) ->
	# 		switch ($1)
	# 			when "yyyy" then return d.getFullYear()
	# 			when "yy" then return (d.getFullYear() % 1000).zf(2)
	# 			when "MM" then return (d.getMonth() + 1).zf(2)
	# 			when "dd" then return d.getDate().zf(2)
	# 			when "E" then return weekName[d.getDay()]
	# 			when "HH" then return d.getHours().zf(2)
	# 			when "hh" then return (if h = d.getHours() % 12 then h else 12).zf(2)
	# 			when "mm" then return d.getMinutes().zf(2)
	# 			when "ss" then return d.getSeconds().zf(2)
	# 			when "a/p" then return if d.getHours() < 12 then "오전" else "오후"
	# 			else return $1
				
	# String.prototype.string = (len) -> s = '', i = 0; while (i++ < len) { s += this; } return s
	# String.prototype.zf =  (len) -> return "0".string(len - this.length) + this
	# Number.prototype.zf = (len) -> return this.toString().zf(len)

	String.prototype.zf =  (len) -> return ("0" + this).slice(-len)
	Number.prototype.zf = (len) -> return this.toString().zf(len)

	###
	Date를 문자로
	@params date [Date]  변환할 Date
	@return [String] 변환 문자열 (오전 9:12)
	###
	@dateToString: (date) -> "#{(if date.getHours() < 12 then "오전" else "오후")} #{if h = date.getHours() % 12 then h else 12}:#{date.getMinutes().zf(2)}"
	
	###
	텍스트 크기
	@params layer [Layer] 
	@params padding [Object]
	@params constraints [Object]
	@return layer
	###
	@textAutoSize: (layer, padding = {}, constraints={}) ->
		@style = layer.style
		@text = layer.html
		sizeAffectingStyles =
			lineHeight: @style["line-height"]
			fontSize: @style["font-size"]
			fontWeight: @style["font-weight"]
			paddingTop: @style["padding-top"]
			paddingRight: @style["padding-right"]
			paddingBottom: @style["padding-bottom"]
			paddingLeft: @style["padding-left"]
			textTransform: @style["text-transform"]
			borderWidth: @style["border-width"]
			letterSpacing: @style["letter-spacing"]
			fontFamily: @style["font-family"]
			fontStyle: @style["font-style"]
			fontVariant: @style["font-variant"]
		size = Utils.textSize @text, sizeAffectingStyles, constraints
		layer.size = width: size.width, height: size.height
		# layer.frame = Utils.frameInset layer.frame, padding
		
		return layer
