ios = require 'ios-kit'
kakaotalk = require 'kakaotalk-kit'

# 리소스
r = (file) -> "images/channel/#{file}"

### 
카카오톡 :: 채널

@auther ho.s
@since 2016.09.12
###
class exports.Channel extends ScrollComponent

	@define "ratio", get: -> Screen.width / 750

	# 생성자
	constructor: (options = {}) ->
		super options
		@props = width: @parent.width, height: @parent.height, backgroundColor: "#F5F5F5", scrollHorizontal: false

		# 네비게이션 :: 오른쪽 :: 내목록
		icMyBucket = ios.utils.svg('<svg width="18px" height="15.5px" viewBox="685 65 36 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
									    <!-- Generator: Sketch 39.1 (31720) - http://www.bohemiancoding.com/sketch -->
									    <desc>Created with Sketch.</desc>
									    <defs></defs>
									    <path d="M695.5459,66 C693.0089,66 690.7729,66.744 688.9109,68.566 C685.0289,72.357 685.0289,78.505 688.9109,82.298 C690.0179,83.383 700.7719,95.101 700.7719,95.101 C701.3849,95.701 702.1899,96 702.9899,96 C703.7929,96 704.5959,95.701 705.2079,95.101 C705.2079,95.101 716.9699,82.665 717.0959,82.54 C720.9749,78.747 720.9749,72.6 717.0959,68.809 C715.1699,66.925 713.0179,65.991 710.3949,65.991 C707.8879,65.991 705.0919,67.949 703.0019,70.026 C700.8489,67.949 698.1389,66 695.5459,66" id="좋아요모음" stroke="none" fill-opacity="0.8" fill="#FFFFFF" fill-rule="evenodd"></path>
									</svg>')
		# 네비게이션바
		@navBar = new ios.NavBar
			left: false
			title: "채널"
			right: new ios.View width: icMyBucket.width, height: icMyBucket.height, backgroundColor:"transparent", html: icMyBucket.svg
			blur: false
			titleColor: "white"
			color: "#DCD7D7"
			backgroundColor: "#493334"
			dividerBackgroundColor: "#493334"
		@navBar.parent = @

		# 리스트 영역조정
		@contentInset = top: @navBar.height

		# 검색바
		@searchBar = new ios.View
			name: "searchBar"
			constraints:
				leading: 0
				trailing: 0
				top: @navBar
				height: 44
			backgroundColor: "white"
			superLayer: @
			shadowY: 1
			shadowColor: "rgba(0,0,0,.06)"
		@searchBar.plac

		# 검색 입력
		@searchBar.label = new ios.Text
			name: ".label"
			text: "검색"
			superLayer: @searchBar
			color: "rgba(0,0,0,.3)"
			fontSize: 16
			constraints: 
				leading: 10
				align: "vertical"

		# 검색 버튼
		@searchBar.btn = new ios.View
			name: ".btn"
			backgroundColor: "#FFEB00"
			constraints:
				trailing: 15
				width: 32
				height: 32
				align: "vertical"
			borderRadius: "100%"
			borderWidth: .5
			borderColor: "#F3E109"
			superLayer: @searchBar

		icSearch = ios.utils.svg('<svg width="15px" height="15px" viewBox="673 29 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
								    <!-- Generator: Sketch 39.1 (31720) - http://www.bohemiancoding.com/sketch -->
								    <desc>Created with Sketch.</desc>
								    <defs>
								        <circle id="path-1" cx="12" cy="12" r="12"></circle>
								        <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="24" height="24" fill="white">
								            <use xlink:href="#path-1"></use>
								        </mask>
								    </defs>
								    <g id="Oval-278-+-Rectangle-511" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(673.000000, 29.000000)">
								        <use id="Oval-278" stroke="#332F00" mask="url(#mask-2)" stroke-width="6" xlink:href="#path-1"></use>
								        <rect id="Rectangle-511" fill="#332F00" transform="translate(23.000000, 23.500000) rotate(-315.000000) translate(-23.000000, -23.500000) " x="16" y="22" width="14" height="3"></rect>
								    </g>
								</svg>')

		@searchBar.btn.icon = new ios.View
			name: ".btn.icon"
			backgroundColor:"transparent"
			constraints:
				leading: 8
				trailing: 8
				top: 8
				bottom: 8
				width: icSearch.width
				height: icSearch.height
				align: "vertical"
			html: icSearch.svg
			superLayer: @searchBar.btn


		s = @width / 750
		# 더미 이미지
		dummy = new Layer 
			width: 750 * s,	height: 9028 * s
			image: r("dummy.png")
			parent: @content
		@updateContent()

		# 상태
		@searchBar.states.add
			left: {}, right: {}
			down: { y: 0 } 
			up: { y: @navBar.height }
			
		# 뎊스 변경
		@navBar.bringToFront()

		# 이벤트 
		@.onScroll => @searchBar.states.switch @direction, time: .3

