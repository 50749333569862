ios = require 'ios-kit'
kakaotalk = require 'kakaotalk-kit'

### 
카카오톡 :: 프로필 :: 관리 :: 문구수정

@auther ho.s
@since 2016.10.06
###
class exports.ProfileManageInput extends Layer

	Events.Confirm = "confirm"

	# 생성자
	constructor: (options = {}) ->
		super options
		@props = width: @parent.width, height: @parent.height, backgroundColor: "#F5F5F5", scrollHorizontal: false
		@parent.visible = true
		@home = options.home

		options.title ?= "제목"
		options.min ?= 0
		options.max ?= -1
		options.text ?= ""

		@title = options.title
		@text = options.text
		@min = options.min
		@max = options.max
		@placeHolder = options.placeHolder

		@onClick -> ""

		icBack = kakaotalk.Utils.svg '<svg width="55px" height="54px" viewBox="13 52 55 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
								    <!-- Generator: Sketch 40.1 (33804) - http://www.bohemiancoding.com/sketch -->
								    <desc>Created with Sketch.</desc>
								    <defs></defs>
								    <path d="M33.6263451,95.194976 L58.7896547,95.194976 C59.8932377,95.194976 60.7878685,96.0827057 60.7878685,97.194976 C60.7878685,98.2995455 59.8935309,99.194976 58.7896547,99.194976 L31.7860823,99.194976 C31.1863122,99.194976 30.6482592,98.9327714 30.2819914,98.5148197 C29.8792272,98.1493201 29.6263451,97.6218678 29.6263451,97.0352388 L29.6263451,70.0316664 C29.6263451,68.9280834 30.5140747,68.0334526 31.6263451,68.0334526 C32.7309146,68.0334526 33.6263451,68.9277903 33.6263451,70.0316664 L33.6263451,95.194976 Z" id="Rectangle-8" stroke="none" fill="#DCD7D7" fill-rule="evenodd" transform="translate(45.207107, 83.614214) rotate(-315.000000) translate(-45.207107, -83.614214) "></path>
								</svg>', 27, 27
		icDeleteAll = ios.utils.svg '<svg width="28px" height="28px" viewBox="687 68 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
									    <!-- Generator: Sketch 40.1 (33804) - http://www.bohemiancoding.com/sketch -->
									    <desc>Created with Sketch.</desc>
									    <defs></defs>
									    <circle id="Oval-46" stroke="none" fill="#CCCCCC" fill-rule="evenodd" cx="701" cy="82" r="14"></circle>
									    <path d="M699.5,81 L694.505241,81 C693.66713,81 693,81.6715729 693,82.5 C693,83.3342028 693.673919,84 694.505241,84 L699.5,84 L699.5,88.9947588 C699.5,89.8328702 700.171573,90.5 701,90.5 C701.834203,90.5 702.5,89.8260806 702.5,88.9947588 L702.5,84 L707.494759,84 C708.33287,84 709,83.3284271 709,82.5 C709,81.6657972 708.326081,81 707.494759,81 L702.5,81 L702.5,76.0052412 C702.5,75.1671298 701.828427,74.5 701,74.5 C700.165797,74.5 699.5,75.1739194 699.5,76.0052412 L699.5,81 Z" id="Rectangle-80" stroke="none" fill="#FFFFFF" fill-rule="evenodd" transform="translate(701.000000, 82.500000) rotate(-315.000000) translate(-701.000000, -82.500000) "></path>
									</svg>'

		# 네비게이션바
		navBar = new ios.NavBar
			left: new ios.View width: icBack.width, height: icBack.height, backgroundColor:"transparent", html: icBack.svg
			title: @title
			right: "확인"
			blur: false
			titleColor: "white"
			color: "rgba(255,255,255,.85)"
			backgroundColor: "#493334"
			dividerBackgroundColor: "#493334"
			superLayer: @
		navBar.right.enable = false

		navBar.left.onClick => @destroy()
		navBar.right.onClick =>
			if navBar.right.enable then @emit Events.Confirm, input.value, @
			@destroy()

		#
		placeHolder = new ios.Text
			name: ".placeHolder"
			constraints:
				top: [navBar, 29]
				leading: 15
			text: @placeHolder
			fontSize: 14
			opacity: .5
			superLayer: @
		#
		input = new kakaotalk.InputField
			name: ".input"
			y: placeHolder.maxY + ios.px(3.5)
			width: @width, height: ios.px(42)
			backgroundColor: "white", borderRadius: ios.px(0), borderWidth: ios.px(.5), borderColor: "#EDEDED"
			style: { paddingLeft: "#{ios.px(21)}px", paddingRight: "#{ios.px(41)}px" }
			type: "text", indent: 0, fontSize: ios.px(15), fontWeight: 400
			placeHolder: @placeHolder, placeHolderFocus: "", placeHolderColor: "rgba(0,0,0,.3)"
			autoCapitalize: false, autoComplete: false, autoCorrect: false, maxLength: (if @max is -1 then undefined else @max)
			value: @text
			parent: @
		input.deleteAll = new ios.View
			name: ".input.deleteAll"
			constraints:
				trailing: 17
				align: "vertical"
			width: icDeleteAll.width
			height: icDeleteAll.height
			html: icDeleteAll.svg
			backgroundColor: "transparent"
			scale: 1/ios.device.scale
			superLayer: input
		input.deleteAll.centerY()

		#
		unless @max is -1
			inputLimit = new ios.Text
				name: ".inputLimit"
				constraints:
					trailing: 13
					bottom: [input, 3]
				text: "#{@text.length}/#{@max}"
				textAlign: "right"
				fontSize: 14
				opacity: .5
				fontFamily: "Helvetica"
				superLayer: @

		# 이벤트 : 입력
		input.on Events.Input, =>
			count = input.value.length
			
			if inputLimit
				inputLimit.html = "#{count}/#{@max}"
				textFrame = ios.utils.textAutoSize(inputLimit)
				inputLimit.props = width: textFrame.width, height: textFrame.height
				inputLimit.maxX = @width - ios.px(13)

			#
			if count > @min and input.value isnt @text
				navBar.right.enable = true
				navBar.right.label.color = "#FFEB00"
			else 
				navBar.right.enable = false
				navBar.right.label.color = "rgba(255,255,255,.85)"

		# 이벤트 : 전체삭제
		input.deleteAll.onClick ->
			input.clear()


	# EVENT HELPER
	onConfirm: (cb) -> @on Events.Confirm, cb

